import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, useLocalStorage, useScriptRef, useWineOne } from 'hooks';

// material-ui
import { BottomNavigation, BottomNavigationAction, Box, Paper, Slide, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

// assets
import homeNor from 'assets/images/navigation/ic_tab_home_nor@3x.png';
import homeSel from 'assets/images/navigation/ic_tab_home_sel@3x.png';
import orderNor from 'assets/images/navigation/ic_tab_order_nor@3x.png';
import orderSel from 'assets/images/navigation/ic_tab_order_sel@3x.png';
import searchNor from 'assets/images/navigation/ic_tab_search_nor_@3x.png';
import searchSel from 'assets/images/navigation/ic_tab_search_sel_@3x.png';
import wishNor from 'assets/images/navigation/ic_tab_wish_nor@3x.png';
import wishSel from 'assets/images/navigation/ic_tab_wish_sel@3x.png';
import myNor from 'assets/images/navigation/ic_tab_my_nor@3x.png';
import mySel from 'assets/images/navigation/ic_tab_my_sel@3x.png';

const useStyles = makeStyles({
  nav: {
    '& .WoNav-icon': {
      display: 'flex',
      height: '34px',
      width: '34px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover'
    },
    '& .nav-button': {
      minWidth: '56px',
      maxWidth: '105px !important'
    },
    '& .nav-button-home .WoNav-icon': { backgroundImage: `url(${homeNor})` },
    '& .nav-button-vendor .WoNav-icon': { backgroundImage: `url(${orderNor})` },
    '& .nav-button-mall .WoNav-icon': { backgroundImage: `url(${searchNor})` },
    '& .nav-button-wish .WoNav-icon': { backgroundImage: `url(${wishNor})` },
    '& .nav-button-my .WoNav-icon': { backgroundImage: `url(${myNor})` },

    '& .nav-button-home.Mui-selected .WoNav-icon': { backgroundImage: `url(${homeSel})` },
    '& .nav-button-vendor.Mui-selected .WoNav-icon': { backgroundImage: `url(${orderSel})` },
    '& .nav-button-mall.Mui-selected .WoNav-icon': { backgroundImage: `url(${searchSel})` },
    '& .nav-button-wish.Mui-selected .WoNav-icon': { backgroundImage: `url(${wishSel})` },
    '& .nav-button-my.Mui-selected .WoNav-icon': { backgroundImage: `url(${mySel})` }
  }
});

/* v1.0.9: 와인검색 안내 툴팁 [start] */
/** 툴팁 노출지연 ms */
const TOOLTIP_SHOW_AFTER = 1200;
/** 툴팁 노출시간 ms */
const TOOLTIP_EXPOSURE_MILLIS = 4000;
/** 툴팁 노출 최대횟수 */
const TOOLTIP_EXPOSURE_COUNT_MAX = 3;

// 툴팁 보이기 타임아웃 ID
let tooltipShowTimeout = null;
// 툴팁 감추기 타임아웃 ID
let tooltipHideTimeout = null;
/* v1.0.9: 와인검색 안내 툴팁 [end] */

/**
 * 1KMWINE 사용자 웹 GNB
 *
 * @authors 조현권<hkcho@wineone.io>
 * @history
 *   - 2023. 01. 31 : 하단 메뉴 위치 변경 및 메뉴명 변경
 *     1. 메뉴순서 변경
 *       - AS-WAS : 홈 > 라이브 > 오더 > 위시 > 마이
 *       - TO-BE  : 홈 > 검색(구 오더) > 라이브 > 위시 > 마이
 *     2. 메뉴명 변경
 *       - 오더 -> 검색
 *
 *  - 2023. 03. 15 : 하단 메뉴 변경 (최효근)
 *    1. 메뉴 변경
 *      - AS-WAS : 홈 > 검색 > 라이브 > 위시 > 마이
 *      - TO-BE  : 홈 > 검색 > 와인샵 > 위시 > 마이
 */
function BottomNav() {
  const theme = useTheme();
  const scriptedRef = useScriptRef();

  const location = useLocation();
  const navigate = useNavigate();

  const { bottomNav } = useWineOne();
  const classes = useStyles();

  const { isLoggedIn, needPinSetting } = useAuth();

  const { show: bnShow } = bottomNav;

  const bottomPaperRef = React.useRef(null);

  // 하단 네비게이션 표현
  const show = React.useMemo(() => {
    // 로그인 안했을 경우 보이지 않기
    if (!isLoggedIn) return false;
    // 핀세팅 중일 떄는 안띄우기
    if (needPinSetting) return false;

    // 화면 URL의 첫 번째 path가 `matchedFirstPaths`에 있는 word면 네비게이션 보이도록
    return typeof bnShow !== 'undefined' ? bnShow : matchedFirstPaths.indexOf(bottomNav.value) >= 0;
  }, [bnShow, bottomNav.value, isLoggedIn, needPinSetting]);

  React.useEffect(() => {
    // state 종속되지 않은 요소에서 참조할 수 있도록 body 태그에 클래스를 토글
    document.body.classList.toggle('is-bottom-nav-show', show);

    return () => {
      document.body.classList.toggle('is-bottom-nav-show', false);
    };
  }, [show]);

  const handleChange = (event, newValue) => {
    // 같은 메뉴를 다시 눌렀을 경우, 화면 최상단으로 스크롤업
    if (location.pathname === `/${newValue}`) {
      try {
        window.scrollTo(0, 0);
      } catch (e) {
        /* DO NOTHING */
      }
    }
    // 메뉴 이동
    else {
      navigate(`/${newValue}`);
    }
  };

  /* v1.0.9: 와인검색 안내 툴팁 [start] */
  // 툴팁 노출 횟수
  const [tooltipShowCount, setTooltipShowCount] = useLocalStorage('tooltip-nav-search', 0);
  // 와인검색 툴팁 보이기
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (showTooltip) {
      console.log('[BottomNav] 필터버튼 안내 툴팁을 보여줍니다.');
      setTooltipShowCount((tooltipShowCount) => {
        console.log(`[MallIndex] 필터버튼 툴팁 노출횟수. ${tooltipShowCount + 1}/${TOOLTIP_EXPOSURE_COUNT_MAX}`);
        return ++tooltipShowCount;
      });

      // #{TOOLTIP_EXPOSURE_MILLIS} millisecond 후 툴팁 감춤
      tooltipHideTimeout = setTimeout(() => {
        if (scriptedRef.current) {
          setShowTooltip(false);
        }
      }, TOOLTIP_EXPOSURE_MILLIS);
    }
  }, [showTooltip]);

  // 툴팁 자동 보이기/감추기
  React.useEffect(() => {
    if (!show) return undefined;
    if (tooltipShowCount >= TOOLTIP_EXPOSURE_COUNT_MAX) {
      console.debug(`[BottomNav] 이미 툴팁 노출횟수를 달성했습니다. [TOOLTIP_EXPOSURE_COUNT_MAX = ${TOOLTIP_EXPOSURE_COUNT_MAX}]`);
      return undefined;
    }

    // #{TOOLTIP_SHOW_AFTER} millisecond 후 툴팁 표현
    tooltipShowTimeout = setTimeout(() => {
      if (scriptedRef.current) setShowTooltip(true);
    }, TOOLTIP_SHOW_AFTER);

    return () => {
      hideTooltip();
    };
  }, [show]);

  // 툴팁 감추기
  const hideTooltip = () => {
    clearTimeout(tooltipShowTimeout);
    clearTimeout(tooltipHideTimeout);

    if (scriptedRef.current) {
      setShowTooltip(false);
    }
  };
  /* v1.0.9: 와인검색 안내 툴팁 [end] */

  // render
  return (
    <Slide direction="up" in={show}>
      <Paper
        ref={bottomPaperRef}
        square
        elevation={3}
        sx={{ position: 'fixed', bottom: 0, left: 0, width: '100vw', zIndex: theme.zIndex.appBar + 10 }}
      >
        <BottomNavigation className={classes.nav} showLabels value={bottomNav.value} onChange={handleChange}>
          {NAV_MENUS.map((nav, i) => (
            <BottomNavigationAction
              key={`bottom-nav-action-${i}`}
              className={`nav-button nav-button-${nav.value}`}
              label={
                <Tooltip
                  open={show && bottomNav.value !== 'mall' && nav.value === 'mall' && showTooltip}
                  arrow
                  title={'모든 와인을 검색할 수 있어요'}
                  componentsProps={{ tooltip: { sx: { marginBottom: '45px !important' } } }}
                  PopperProps={{
                    container: bottomPaperRef.current
                  }}
                >
                  <Typography fontSize="inherit" fontWeight="inherit">
                    {nav.label}
                  </Typography>
                </Tooltip>
              }
              value={nav.value}
              icon={<NavIcon />}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  pt: '1px'
                }
              }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Slide>
  );
}

export default BottomNav;

const NavIcon = React.memo(() => {
  return <Box className="WoNav-icon" />;
});

// 네비게이션 메뉴 - 배열 순서대로 GNB에 표현됨
const NAV_MENUS = Object.freeze([
  { label: '홈', value: 'home' },
  { label: '검색', value: 'mall' },
  { label: '와인샵', value: 'vendor' },
  { label: '위시', value: 'wish' },
  { label: '마이', value: 'my' }
]);

// 하단 네비를 표현할 path
const matchedFirstPaths = (() => NAV_MENUS.map(({ value }) => value))();
