import React from 'react';
import PropTypes from 'prop-types';
import { useLocationState } from 'react-router-use-location-state';
import './cartOrderStyle.scss';

// material-ui
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// import projects
import { ProductCurrency, ProductPrice, WoDialogTitle, WoDivider } from 'components';
import { ProductImage } from 'components/v2';
import { Calculator } from 'utils/bourgogne-tools';

// assets
import wineDefaultImg from 'assets/images/default_wine.webp';
import CartImg from 'assets/images/ic_order_cart.png';
import { makeStyles } from '@mui/styles';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';

/**
 * 프로모션 상품주문 하단 버튼시트
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
function BottomButtonSheet({
  product,
  orderItemMap = {},
  onCartButtonClick,
  onOrderButtonClick,
  onDeleteClick,
  totalPrice,
  totalBottleCount = 0,
  cartLoading
}) {
  const classes = useStyles();

  // 선택된 상품목록 개수
  const SELECTED_ITEMS_COUNT = React.useMemo(() => Object.keys(orderItemMap).length, [orderItemMap, product]);

  // 상품정보 영역 open 여부
  const [orderInfoOpen, setOrderInfoOpen] = useLocationState('promotion_orderProductInfoOpen', false);

  const handleSheetAction = () => {
    setOrderInfoOpen(() => false);
  };

  // css transition
  React.useEffect(() => {
    if (SELECTED_ITEMS_COUNT === 0) setOrderInfoOpen(SELECTED_ITEMS_COUNT !== 0);
  }, [orderItemMap, product]);

  const generateListItem = React.useCallback(
    (product, quantity) => {
      const imgSrc = product?.pdata?.bottle_img?.thumb ?? wineDefaultImg;
      const vintageStr = product?.vintage === 'NV' ? '' : `${product?.vintage}년 | `;
      const capacityStr = `${Calculator.formatCapacity(product.capacity)} | `;
      const secondaryStr = `${vintageStr}${capacityStr}${quantity}병`;

      return (
        <ListItem
          key={`selectd-prd-${product?._id}`}
          secondaryAction={
            <IconButton edge="end" aria-label="선택 취소" onClick={() => onDeleteClick({ product })}>
              <CloseIcon />
            </IconButton>
          }
          sx={{ px: 0 }}
        >
          <ListItemAvatar sx={{ maxHeight: '40px' }}>
            <ProductImage width={40} height={40} src={imgSrc} alt={product?.name?.ko ?? '상품 이미지'} padding="4px" />
          </ListItemAvatar>
          <ListItemText
            primary={product?.name?.ko}
            primaryTypographyProps={{ noWrap: true, sx: { pr: '40px' } }}
            secondary={secondaryStr}
          />
        </ListItem>
      );
    },
    [onDeleteClick]
  );

  // 선택된 상품목록
  const listItems = React.useMemo(
    () =>
      Object.keys(orderItemMap).map((productId) => {
        const { product, quantity } = orderItemMap[productId];
        return generateListItem(product, quantity);
      }),
    [orderItemMap]
  );

  // render
  return (
    <Box component="section" className={`${classes.bottomSheet} bottom-button-sheet ${SELECTED_ITEMS_COUNT > 0 ? 'open' : 'close'} trans `}>
      {/* 선택한 상품 목록 영역 */}
      <Box className={`order-info ${orderInfoOpen ? 'open' : 'close'}`}>
        <WoDialogTitle
          title="선택한 상품"
          onClickClose={() => handleSheetAction()}
          tools={[<Typography variant="caption">총 {totalBottleCount}병</Typography>]}
        />
        <WoDivider />
        <Box className={classes.productBox}>
          {/* 상품목록 */}
          <List>{listItems}</List>
        </Box>
      </Box>

      {/* 선택한 상품 요약 영역 (총 상품 병수, 가격) */}
      <Box className={`order-summary ${SELECTED_ITEMS_COUNT > 0 ? 'open' : 'close'}`}>
        <Box zIndex={1203}>
          <Box px="20px" py="16px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="14px" component="div" sx={{ height: '1rem' }}>
              총&nbsp;{totalBottleCount.toLocaleString()}
              <span>병</span>
            </Typography>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <ProductPrice sx={{ color: '#5E28AC', fontWeight: 800 }}>{totalPrice.toLocaleString()}</ProductPrice>
              <ProductCurrency multiple={false} pl="1px" />
              <Box pl={1}>
                <ButtonBase onClick={() => setOrderInfoOpen(!orderInfoOpen)} sx={{ borderRadius: '50%' }}>
                  <ArrowCircleButton component="div" on={orderInfoOpen} />
                </ButtonBase>
              </Box>
            </Box>
          </Box>
        </Box>
        <WoDivider />
      </Box>

      {/* 장바구니 & 주문하기 버튼 영역 */}
      <ButtonGroupWrapper display="flex" alignItems="center" width={1} px={2.5}>
        <Grid container flexGrow={1} spacing={1}>
          <Grid item xs="auto">
            <ButtonBase aria-label="장바구니" sx={{ padding: 0, borderRadius: '5px' }} onClick={onCartButtonClick}>
              <Box className={classes.cartButtonWrapper}>
                {cartLoading ? (
                  <CircularProgress size={24} color="brand" />
                ) : (
                  <Box component="img" src={CartImg} sx={{ width: '34px', height: '34px' }} />
                )}
              </Box>
            </ButtonBase>
          </Grid>
          <Grid item xs>
            <OrderButton onClick={onOrderButtonClick} disabled={cartLoading} />
          </Grid>
        </Grid>
      </ButtonGroupWrapper>
    </Box>
  );
}

BottomButtonSheet.propTypes = {
  product: PropTypes.object,
  orderItemMap: PropTypes.object,
  onCartButtonClick: PropTypes.func.isRequired,
  onOrderButtonClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  totalPrice: PropTypes.number,
  totalBottleCount: PropTypes.number,
  cartLoading: PropTypes.bool.isRequired
};

export default BottomButtonSheet;

export const ButtonGroupWrapper = styled(Box)`
  padding-top: 8px;
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
`;

// eslint-disable-next-line react/prop-types
const OrderButton = React.memo(({ onClick, disabled }) => (
  <Button
    fullWidth
    variant="contained"
    color="brand"
    onClick={onClick}
    sx={{ height: 46, fontSize: 17, fontWeight: 800, letterSpacing: '-0.17px' }}
    disabled={disabled}
  >
    주문하기
  </Button>
));

const useStyles = makeStyles((theme) => ({
  bottomSheet: {
    position: 'fixed',
    bottom: 0,
    // left: 'auto',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    right: 'auto',
    height: 'auto',
    zIndex: 1200,
    backgroundColor: '#fff',
    border: `1px solid ${alpha(theme.palette.brand.main, 0.1)}`,
    maxWidth: '444px',
    borderBottom: 'none',
    boxShadow: '0px -2px 10px 0 rgba(0, 0, 0, 0.16)'
  },
  productBox: {
    padding: '0 20px',
    maxHeight: 185,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  cartButtonWrapper: {
    border: '1px solid #220348',
    width: '46px',
    height: '46px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px'
  }
}));

function ArrowCircleButton({ on = false, onClick = () => {}, component = 'button' }) {
  // render
  return (
    <ArrowButton
      component={component}
      aria-label="상품 선택정보"
      size="small"
      edge="end"
      color="brand"
      sx={{ p: '2px', m: 0 }}
      onClick={onClick}
    >
      <KeyboardArrowUpSharpIcon fontSize="inherit" sx={{ transform: `rotate(${on ? '180' : '0'}deg)` }} />
    </ArrowButton>
  );
}

ArrowCircleButton.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  component: PropTypes.string
};

const ArrowButton = styled(IconButton)(({ theme }) => ({
  marginLeft: '4px',
  marginBottom: '3px',
  border: `1px solid ${theme.palette.brand.main}`,
  borderRadius: '50%',
  transition: `all ${theme.transitions.duration.short} ${theme.transitions.easing.easeInOut}`
}));
