import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocationState } from 'react-router-use-location-state';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './SelfPromotionStyle.scss';

// project imports
import { useScriptRef } from 'hooks';
import { useSelector } from 'store';
import { SpaceBetweenBox } from 'components';
import { getSelfPromotion, getSelfPromotionList } from 'services/PromotionService';
import ProductCard from './components/productCard';

// material-ui
import { Box, ButtonBase, Chip, CircularProgress, Container, Fade, Paper, Tooltip, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

// assets
import PromotionChipIcon from './components/assets/ico_home_discount.png';

const useStyles = makeStyles({
  chipSwiperWrapper: {
    '& .swiper-slide': {
      width: 'auto'
    }
  },
  promotionChip: {
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    '&.MuiChip-clickable:hover': { backgroundColor: '#FFFFFF' }
  }
});

// 조회할 프로모션 페이지 사이즈
const PROMOTION_PAGE_SIZE = 15;

/**
 * 셀프 프로모션 섹션 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>, 조현권<hkcho@wineone.io>, 최효근<hkchoi@winone.io>
 */
function SelfPromotionSection() {
  const scriptedRef = useScriptRef();
  const classes = useStyles();

  const { userLocation } = useSelector((state) => state);

  const navigate = useNavigate();

  const selfPromotionSectionRef = React.useRef(null);
  const chipSwiperRef = React.useRef(null);
  const cardSwiperRef = React.useRef(null);

  let SWIPER_INDEX = 0;

  // card swiper state
  const [cardSwiper, setCardSwiper] = React.useState(null);

  // 프로모션 목록
  const [promotionList, setPromotionList] = useLocationState('main_self_promo', { loaded: false, coord: {}, data: [] });

  // 프로모션 위치 기억
  const [promoChipIdx, setPromoChipIdx] = useLocationState('promo_chip_idx', 0);

  // 프로모션 상품 목록
  const [promotionProductList, setPromotionProductList] = useLocationState('main_self_promo_products', {
    loading: false,
    data: []
  });

  // 선택된 프로모션
  const [activePromotion, setActivePromotion] = useLocationState('active_self_promotion', null);

  const [swiperIndexState, setSwiperIndexState] = useLocationState();

  // 뒤로가기로 접근 시 swiper index 세팅
  React.useEffect(() => {
    if (swiperIndexState && typeof swiperIndexState === 'number' && cardSwiper) {
      SWIPER_INDEX = swiperIndexState;
      cardSwiperRef.current.swiper.slideTo(SWIPER_INDEX);
    }
  }, [swiperIndexState, cardSwiper]);

  // 클릭한 칩의 내용을 state로 업데이트
  const handleChipClick = React.useCallback(
    (promotion) => {
      SWIPER_INDEX = 0;
      setSwiperIndexState(SWIPER_INDEX);

      if (promotion._id !== activePromotion._id) {
        setActivePromotion(promotion);
        fetchSelfPromotionProductList(promotion._id);
      }
    },
    [activePromotion]
  );

  // 프로모션 목록 조회
  const fetchSelfPromotionList = async () => {
    // request body
    const body = {
      page: { current: 1, size: PROMOTION_PAGE_SIZE },
      states: ['ING'],
      with_product: false,
      order_by: [['distance', 'asc']],
      coordinate: userLocation.coord
    };

    // 프로모션 목록 조회
    const result = await getSelfPromotionList(body).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PromotionSection][fetchPromotionList] Unmounted component.');
      return;
    }

    // 프로모션 목록 조회 중 오류 발생
    if (result.error) {
      console.error('[홈] 프로모션 목록 조회 중 오류가 발생했습니다.', result.error);
      setPromotionList({ loaded: false, coord: {}, data: [] });
      return;
    }

    // 프로모션 목록
    setPromotionList({ loaded: true, coord: userLocation.coord, data: [...result.promotions] });
    setActivePromotion(result.promotions[0]);

    if (result.promotions?.length) {
      // 프로모션 상품목록 조회
      await fetchSelfPromotionProductList(result.promotions[0]._id);
    } else {
      console.log('[PromotionSection] 진행 중인 프로모션이 없습니다.');
    }
  };

  // 프로모션 상품목록 조회
  const fetchSelfPromotionProductList = async (id) => {
    setPromotionProductList({ loading: true, data: [] });
    const result = await getSelfPromotion(id).catch((error) => ({ error }));

    if (!scriptedRef.current) {
      console.warn('[PromotionSection][fetchPromotionProductList] Unmounted component.');
      return;
    }

    if (result.error) {
      setPromotionProductList({ loading: false, data: [] });
      return;
    }

    setPromotionProductList({ loading: false, data: [...result.products] });
  };

  // 유저가 좌표 위치를 변경할 때마다 새로 호출
  React.useEffect(() => {
    if (promotionList?.coord?.lat !== userLocation?.coord?.lat || promotionList?.coord?.lng !== userLocation?.coord?.lng) {
      SWIPER_INDEX = 0;
      setSwiperIndexState(SWIPER_INDEX);
      setActivePromotion(null);
      setPromotionList({ loaded: false, coord: {}, data: [] });
      fetchSelfPromotionList();
    } else if (!promotionList.loaded) {
      fetchSelfPromotionList();
    }
  }, [userLocation?.coord]);

  // 툴팁관련
  const [toolTipOpen, setToolTipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setToolTipOpen(false);
  };

  const handleTooltipOpen = () => {
    setToolTipOpen(true);
  };

  const sectionTitle = '와인샵 특가';
  // const sectionTitle = React.useMemo(() => {
  //   const nowMmt = moment.utc();
  //   // 24.06.17. 11:00 ~ 24.06.23. 23:00 까지는 로드샵 페스티벌로 이름이 뜨도록
  //   // UTC 시간으로 - 24.06.17 02:00 ~ 24.06.23 14:00
  //   const startMmt = moment.utc('24072502', 'YYMMDDHH');
  //   const endMmt = moment.utc('24073114', 'YYMMDDHH');
  //   // console.log('#nowMmt.isBetween(startMmt, endMmt): ', nowMmt.isBetween(startMmt, endMmt));
  //   if (nowMmt.isBetween(startMmt, endMmt)) {
  //     return '로드샵 페스티벌';
  //   }
  //
  //   return '와인샵 특가';
  // }, []);

  // render
  return (
    <Fade in={promotionList.loaded && promotionList.data.length > 0} appear={!promotionList.loaded} unmountOnExit>
      <Box mt="32px" sx={{ overflowX: 'hidden' }}>
        <Paper square component="section" elevation={0}>
          <Container ref={selfPromotionSectionRef} aria-label="와인샵 특가" component="article" position="relative">
            <SpaceBetweenBox>
              <Box display="flex" alignItems="center">
                <Box component="img" src={PromotionChipIcon} width="23px" height="22px" ml="4px" />
                <Typography variant="subtitle1" fontWeight={800} fontSize={21} letterSpacing="0.4px" noWrap sx={{ pl: 0.5 }}>
                  {sectionTitle}
                </Typography>
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#220348',
                        borderRadius: '8px',
                        paddingX: '8px',
                        paddingY: '11px'
                      }
                    },
                    arrow: {
                      sx: {
                        marginLeft: '2px',
                        color: '#220348'
                      }
                    }
                  }}
                  PopperProps={{
                    container: selfPromotionSectionRef.current,
                    sx: ({ zIndex }) => {
                      return { zIndex: (zIndex.appBar ?? 1200) - 1 };
                    },
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -27]
                        }
                      }
                    ]
                  }}
                  arrow
                  onClose={handleTooltipClose}
                  placement="bottom-start"
                  open={toolTipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Box display="flex">
                      <Typography fontSize="12px" fontWeight="400" lineHeight="16px">
                        설정하신 핀으로 부터
                        <br /> 거리순으로 정렬됩니다.
                      </Typography>
                      <Box ml="8px">
                        <ButtonBase
                          onClick={() => {
                            handleTooltipClose();
                          }}
                        >
                          <CloseIcon style={{ width: '16px', height: '16px' }} />
                        </ButtonBase>
                      </Box>
                    </Box>
                  }
                >
                  <ButtonBase onClick={handleTooltipOpen}>
                    <InfoOutlinedIcon
                      style={{ marginLeft: '4px', width: '19px', marginBottom: '2px', height: '19px', color: 'rgba(34, 3, 72, 0.58)' }}
                    />
                  </ButtonBase>
                </Tooltip>
              </Box>

              {promotionList.data.length > 0 && (
                <ButtonBase
                  onClick={() => {
                    setSwiperIndexState(SWIPER_INDEX);
                    navigate('/home/self-promotions', { state: { activePromotion } });
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography fontSize="11px" sx={{ height: 14, mb: '5px' }}>
                      전체보기
                    </Typography>
                    <KeyboardArrowRightIcon sx={{ opacity: '50%', width: '14px', marginBottom: '2px' }} />
                  </Box>
                </ButtonBase>
              )}
            </SpaceBetweenBox>
          </Container>
        </Paper>
        {/* 프로모션 목록 */}
        {promotionList.data.length > 0 && activePromotion !== null && (
          <Box mt="16px" mb="24px" px="20px" display={promotionList.data.length > 1 ? 'block' : 'none'}>
            <Swiper
              ref={chipSwiperRef}
              spaceBetween={4}
              slidesPerView="auto"
              freeMode
              modules={[FreeMode]}
              initialSlide={promoChipIdx}
              className={classes.chipSwiperWrapper}
            >
              {promotionList.data.map((promotion, i) => {
                let label = promotion.vendor.biz.name;
                if (promotion.vendor.biz.rep_area_nm) {
                  label += ` (${promotion.vendor.biz?.rep_area_nm})`;
                }

                return (
                  <SwiperSlide key={`home-promotion-chip-${promotion._id}`}>
                    <Chip
                      variant="outlined"
                      label={label}
                      className={classes.promotionChip}
                      sx={{
                        border: promotion._id === activePromotion._id ? '1.5px solid #9357E5' : '1.5px solid #EFEDF0',
                        color: promotion._id === activePromotion._id ? '#9357E5' : '##140229'
                      }}
                      onClick={() => {
                        setPromoChipIdx(i);
                        chipSwiperRef.current.swiper.slideTo(i);
                        handleChipClick(promotion);
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}

        {/* 프로모션 상품목록 */}
        <Box pt={1}>
          <Box position="relative" height="294px">
            <Typography fontSize="16px" fontWeight="600" mb="14px" mx="20px">
              {activePromotion?.name}
            </Typography>
            {promotionProductList.loading && (
              <Box width="100%" height="200px" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress color="primary" />
              </Box>
            )}
            {activePromotion && !promotionProductList.loading && promotionProductList.data.length > 0 && (
              <Swiper
                onSwiper={setCardSwiper}
                className="cardSwiper"
                ref={cardSwiperRef}
                slidesPerView="auto"
                centeredSlides
                centeredSlidesBounds
                spaceBetween={16}
                onSlideChange={(e) => {
                  SWIPER_INDEX = e.activeIndex;
                }}
              >
                {promotionProductList.data.map((product, index) => (
                  <SwiperSlide key={`home-self-promo-card-${index}`} style={{ width: '323px' }}>
                    <ProductCard width="323px" product={product} index={index} setSwiperIndexState={setSwiperIndexState} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}

export default SelfPromotionSection;
