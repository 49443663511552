import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// 상품명
const ProductName = styled((props) => <Typography className={'product-name'} {...props} />)(({ theme, noWrap = true }) => ({
  textAlign: 'left',
  fontSize: '14px',
  lineHeight: '16px',
  minHeight: noWrap ? '2rem' : 'auto',
  height: noWrap ? '2rem' : 'auto',
  display: noWrap ? '-webkit-box' : 'block',
  overflow: noWrap ? 'hidden' : 'inherit',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'break-all',
  opacity: 0.7
}));

export default ProductName;
