import React from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import { APP_TYPE } from 'store/slices/wine-one';
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { APP_SCHEME, PAYMENT } from 'config';

// material-ui
import { Box, Button, Chip, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const { navigator } = window;

/**
 * 이니시스 결제요청 페이지
 * @constructor
 */
function PaymentInicis() {
  const scriptedRef = useScriptRef();

  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  React.useEffect(() => {
    if (navigationType !== 'PUSH') {
      console.log(`[PaymentInicis.jsx] navigationType='${navigationType}'. 이전화면으로 돌아갑니다.`);
      navigate(-1);
    }
  }, [navigationType]);

  if (!location.state || !location.state.order) {
    WoAlert.fire(`올바르지 않은 접근입니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
      navigate(-1);
    });
    return null;
  }

  // 뒤로가기 버튼 보이기
  const [showBackButon, setShowBackButton] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (scriptedRef.current) {
        setShowBackButton(true);
      }
    }, 3000);
  }, []);

  const {
    appType // 접근 앱 타입
  } = useWineOne();
  const {
    user // 현재 사용자
  } = useAuth();

  React.useLayoutEffect(() => {
    if (appType === APP_TYPE.WEB) {
      console.info(`[1KMWINE] 쿠키허용여부 확인. [navigator.cookieEnabled=${navigator.cookieEnabled}]`);
      if (!navigator.cookieEnabled) {
        WoAlert.fire(`쿠키 허용이 되지 않은 앱입니다.\n쿠키허용 후 결제가 가능합니다.`, '', 'error').then(() => {
          navigate(-1);
        });
        return null;
      }
    }
  }, []);

  // 이니시스(inicis) 복합/옵션 파라미터
  let P_RESERVED = 'below1000=Y&twotrs_isp=Y&block_isp=Y&twotrs_isp_noti=N';

  // iOS 앱에서 호출할 경우
  if (appType === APP_TYPE.IOS) {
    P_RESERVED = `below1000=Y&app_scheme=${APP_SCHEME.IOS_1KMWINE}://`;
  }

  // 결제(payment)정보 요청을 위한 주문(order)정보
  const { order } = location.state;
  // 이니시스(inicis) 전송요청 데이터
  const formData = { ...order };
  // console.log('이니시스에 전달할 데이터: ', formData);

  // 주문자 정보 (현재 로그인 사용자)
  const { currentUser } = getAuth();
  // console.log('현재 로그인사용자 firebase authentication: ', currentUser);
  // console.log('현재 로그인사용자 data :', user);

  // 주문아이디(Order ID)
  const { oid } = order;

  // 가맹점 임의 데이터
  const P_NOTI = JSON.stringify({
    oid,
    vendor: formData.vendor,
    user: {
      // accessToken: currentUser.accessToken,
      uid: currentUser.uid
    },
    callback: {
      success: `${window.location.origin}/payment/complete`
    }
  });

  // formRef
  const formRef = React.useRef(null);

  React.useEffect(() => {
    // console.log('######### formRef: ', formRef);
    if (formRef.current) {
      console.groupCollapsed('[wineone] 결제요청 form 준비 완료.');
      // 현재요청 호스트
      console.info('current origin: ', window.location.origin);
      console.info('P_NOTI: ', P_NOTI);
      console.info('navigationType: ', navigationType);
      console.groupEnd();

      if (navigationType === 'PUSH') {
        formRef.current.submit();
      }
    }
    // console.log('----------------------------------> navigationType=', navigationType);
  }, [formRef]);

  // render
  return (
    <Box height={`calc(var(--vh, 1vh) * 100)`}>
      <LoaderWrapper>
        <LinearProgress color="primary" />
      </LoaderWrapper>
      <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Chip icon={<CircularProgress color={'inherit'} size={14} />} label={'결제요청 중 입니다'} color={'gray'} variant="outlined" />
        <Box display="block" mt={1}>
          {showBackButon && (
            <Button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              이전으로
            </Button>
          )}
        </Box>
      </Box>

      {/* 이니시스 결제요청 form[start] - document: https://manual.inicis.com/mobile/#pay */}
      <form
        ref={formRef}
        name="mobileweb"
        action="https://mobile.inicis.com/smart/payment/"
        target="_self"
        method="post"
        acceptCharset="euc-kr"
        style={{ display: 'none' }}
      >
        {/* 리턴받는 가맹점 URL 세팅 */}
        <input
          type="text"
          name="P_NEXT_URL"
          defaultValue={`${PAYMENT.INICIS.P_NEXT_URL}?oid=${encodeURIComponent(formData.oid)}`}
          readOnly
        />
        {/* 지불수단 선택 (신용카드,계좌이체,가상계좌,휴대폰) */}
        <input type="text" name="P_INI_PAYMENT" defaultValue="CARD" readOnly />
        {/* 복합/옵션 파라미터 [에스크로옵션 : useescrow=Y] */}
        <input type="text" name="P_RESERVED" defaultValue={P_RESERVED} readOnly />
        {/* 상점 아이디 */}
        <input type="text" name="P_MID" defaultValue={PAYMENT.INICIS.P_MID} readOnly />
        {/* 주문아이디 (Order ID) */}
        <input type="text" name="P_OID" defaultValue={formData.oid} readOnly />
        {/* 주문명 */}
        <input type="text" name="P_GOODS" defaultValue={formData.goods_name} readOnly />
        {/* 결제금액 */}
        <input type="text" name="P_AMT" defaultValue={`${formData.pay.amount}`} readOnly />
        {/* 구매자 이름 */}
        <input type="text" name="P_UNAME" defaultValue={user.uname} readOnly />
        {/* 구매자 이메일(email) 주소 */}
        {currentUser.email != null && <input type="text" name="P_EMAIL" defaultValue={currentUser.email} readOnly />}
        {/* 구매자 휴대폰번호(phone number) */}
        {user.mobile != null && <input type="text" name="P_MOBILE" defaultValue={user.mobile} readOnly />}
        {/* {currentUser.phoneNumber != null && <input type="text" name="P_MOBILE" defaultValue={currentUser.phoneNumber} readOnly />} */}

        {/* 휴대폰결제 필수 [1:컨텐츠, 2:실물] */}
        <input type="text" name="P_HPP_METHOD" defaultValue="2" readOnly />

        {/* 결과수신 인코딩 */}
        <input type="text" name="P_CHARSET" defaultValue="utf8" readOnly />
        {/* 가맹점 임의 데이터 */}
        <input type="text" name="P_NOTI" defaultValue={P_NOTI} readOnly />
      </form>
      {/* 이니시스 결제요청 form[end] */}
    </Box>
  );
}

export default PaymentInicis;

const LoaderWrapper = styled('div')(
  ({ theme }) => `
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: ${theme.zIndex.appBar + 5},
  width: '100%'
`
);
