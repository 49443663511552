import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// project imports
import { CLO_CODE, DEV } from 'config';
import { useAmplitude, useKakaoMap, useScriptRef, useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { useSelector } from 'store';
import { getVendorMapList } from 'services/VendorService';
import VendorMapCard from './components/VendorMapCard';
import { CurrentLocationIcon, WineBottleLoadingLottie } from 'components';
import { VendorQrDialog } from '../../../../promotion';

// material-ui
import { Box, Fab, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets
import './VendorListMapStyles.css';
import { useLocationState } from 'react-router-use-location-state';
import ArrowBackImg from 'assets/images/arrow-back.png';

const BOTTOM_COMPONENT_HEIGHT = 122;

/**
 * 샵 지도
 *
 * @authors 최효근<hkchoi@wineone.io>
 *
 */
function VendorListMap({ height = '100%', width = '100%' }) {
  const scriptedRef = useScriptRef();
  const theme = useTheme();
  const { user } = getAuth();
  const location = useLocation();

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  // wineone context
  const { geolocation, refreshUserGeolocation } = useWineOne(); // wineone context

  const { logEvent } = useAmplitude();

  // 카카오 지도
  const { kakaoMaps, markerImages } = useKakaoMap();

  // 사용자 위치정보
  const { userLocation } = useSelector((state) => state);

  // 사용자 위치마커
  const userMarker = React.useRef(null);

  // 입점사 마커 객체
  const vendorMarkerObj = React.useRef({});

  // 카카오 맵이 들어갈 div
  const vendorMapDivRef = React.useRef(null);

  // 마지막으로 선택된 vendor
  const [lastSelectedVendor, setLastSelectedVendor] = useLocationState('vl_last_selected_v', null);
  // 선택된 vendor
  const [selectedVendor, setSelectedVendor] = React.useState(navigationType !== 'PUSH' ? lastSelectedVendor : null);
  const selectedVendorId = React.useRef(selectedVendor?.vendor_id ?? null);

  React.useEffect(() => {
    refreshUserGeolocation();
  }, []);

  React.useEffect(() => {
    selectedVendorId.current = selectedVendor?.vendor_id ?? null;
  }, [selectedVendor?.vendor_id]);

  // 지도
  const [vendorMap, setVendorMap] = React.useState(null);

  // 카카오지도 초기화[start]
  const initializeKakaoMap = React.useCallback(
    (lat, lng) => {
      if (kakaoMaps?.readyState !== 2) {
        console.warn('[VendorListMap] 카카오 지도 스크립트를 불러오고 있습니다.');
        return;
      }
      // 지도 옵션
      const _map = new kakaoMaps.Map(vendorMapDivRef.current, {
        center: new kakaoMaps.LatLng(lat, lng),
        level: 6, // 기본 줌 레벨
        minLevel: 2,
        maxLevel: 11 // 최대 줌 레벨
      });

      if (scriptedRef.current) setVendorMap(_map);
    },
    [kakaoMaps?.readyState]
  );
  // 카카오지도 초기화[end]

  // 사용자 위치 변경됨
  React.useEffect(() => {
    if (kakaoMaps === null) {
      console.debug('[VendorListMap] 카카오 지도 스크립트를 불러오고 있습니다.');
      return;
    }
    const { access, initialized, latitude, longitude } = geolocation;
    if (!initialized) console.debug('[VendorListMap] 사용자의 위치를 확인하는 중...');
    if (!access) {
      console.warn('[VendorListMap] 사용자의 위치정보에 접근할 수 없습니다.');
      return undefined;
    }
    if (!vendorMap) {
      console.debug(`[VendorListMap] 'vendorMap' not initialized yet...`);
      return undefined;
    }
    if (userMarker.current === null) {
      userMarker.current = new kakaoMaps.Marker({
        image: markerImages.currentUser
      });
    }

    // 사용자 현 위치마커 표현 - 마커 위치지정
    userMarker.current.setPosition(new kakaoMaps.LatLng(latitude, longitude));
    if (vendorMap) {
      userMarker.current.setMap(vendorMap);
    }

    return () => {
      if (userMarker.current) {
        userMarker.current.setMap(null);
        userMarker.current = null;
      }
    };
  }, [kakaoMaps, vendorMap, geolocation]);

  // 지도 중심좌표 변경 이벤트 callback
  const mapBoundChanged = () => {
    requestVendorList();
  };

  React.useEffect(() => {
    if (vendorMap) {
      // 맵 중심/줌 변경 이벤트 리스너 등록
      kakaoMaps.event.addListener(vendorMap, 'idle', mapBoundChanged);

      requestVendorList(); // 입점사 목록 조회
    }
    return () => {
      try {
        if (vendorMap) kakaoMaps.event.removeListener(vendorMap, 'idle', mapBoundChanged);
      } catch (e) {
        /* DO NOTHING */
      }
    };
  }, [vendorMap]);

  React.useEffect(() => {
    if (kakaoMaps?.readyState !== 2) return;
    if (vendorMapDivRef.current) {
      if (vendorMap == null) {
        if (userLocation.coord) {
          console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `카카오지도 초기화 시작`);
          // 지도 초기화
          if (selectedVendor) {
            initializeKakaoMap(selectedVendor.location.latitude, selectedVendor.location.longitude);
          } else {
            initializeKakaoMap(userLocation.coord.lat, userLocation.coord.lng);
          }
        } else {
          console.debug('현재 위치가 아직 파악되지 않았습니다.', geolocation);
          if (geolocation.initialized && typeof geolocation.latitude !== 'undefined') {
            console.debug('현재 실제 위치를 중심으로 지도를 그립니다.', geolocation);
            initializeKakaoMap(geolocation.latitude, geolocation.longitude);
          }
        }
      } else {
        console.debug(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `이미 초기화된 카카오지도가 있습니다.`);
      }
    }
  }, [kakaoMaps?.readyState, vendorMapDivRef, geolocation, userLocation]);

  // 사용자 핀위치 표현
  React.useEffect(() => {
    if (!vendorMap) return;

    // 현재 핀 이름
    const content = `<div class="customoverlay"><span class="title">${userLocation.currentPin.name}</span></div>`;
    const position = new kakaoMaps.LatLng(userLocation.currentPin.location.latitude, userLocation.currentPin.location.longitude);
    const customOverlay = new kakaoMaps.CustomOverlay({ map: vendorMap, position, content, yAnchor: 1, zIndex: 10 });
    console.debug('-> customOverlay: ', customOverlay);
  }, [userLocation.currentPin, vendorMap]);

  // 최초 조회
  React.useEffect(() => {
    if (kakaoMaps === null || vendorMap === null) {
      console.warn('[VendorListMap] 카카오지도 스크립트가 로드되지 않았습니다.');
      return;
    }
    requestVendorList(); // 최초 조회
  }, [vendorMap?.getBounds()]);

  // 샵정보 가지고오기
  const requestVendorList = async () => {
    // 지도영역 확인
    const bounds = vendorMap.getBounds();

    // 지도영역의 대각선 거리 구하기
    const coordNE = bounds.getNorthEast();
    const coordSW = bounds.getSouthWest();

    // 지도 꼭지점 위도경도
    const squarePoint = [
      `${coordSW.getLng()} ${coordNE.getLat()}`,
      `${coordNE.getLng()} ${coordNE.getLat()}`,
      `${coordNE.getLng()} ${coordSW.getLat()}`,
      `${coordSW.getLng()} ${coordSW.getLat()}`
    ];
    // console.debug('[VendorListMap] 입점샵 목록 조회 시작', squarePoint);

    const results = await getVendorMapList(squarePoint).catch((error) => {
      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `주변 입점샵 검색중 에러#1`,
        msg: `[uid=${user?._id ?? 'anonymous'}] ${JSON.stringify(error.message)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id,
          error
        }
      })
        .then(console.log)
        .catch(console.error);
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[VendorListMap] Unmounted component.');
      return;
    }

    console.debug(`[wineone] 주변 입점샵 검색결과 => `, results);
    if (results.error) {
      await WoAlert.fire(`와인샵을 불러오는 중 오류가 발생했습니다.`, '', 'error').then(() => {
        navigate(-1);
      });
      return;
    }

    const { result, data } = results;

    if (result.code !== 0) {
      const { msg } = result;
      console.error('주변 입점샵 검색중 에러.', msg);

      httpsCallable(
        getFirebaseFunctions(),
        'call-cdm-clo-error'
      )({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `주변 입점샵 검색중 에러`,
        msg: `[uid=${user?._id ?? 'anonymous'}] ${JSON.stringify(msg)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id,
          msg
        }
      })
        .then(console.log)
        .catch(console.error);

      return undefined;
    }

    data.forEach((result) => {
      // vendor id
      const vendor_id = result._id;

      // vendor marker object
      let vendorMarker = vendorMarkerObj.current[vendor_id];

      // 기존 마커가 없을 경우
      let isNewMarker = false;

      if (!vendorMarker) {
        isNewMarker = true;
        vendorMarker = new kakaoMaps.Marker({ clickable: true, zIndex: 2 });
      }

      // Vendor marker location
      const lat = result.biz__location.y;
      const lng = result.biz__location.x;

      const vendorPosition = new kakaoMaps.LatLng(lat, lng);

      // const markerImg = selectedVendor?.vendor_id === vendor_id ? markerImages.vendor.on : markerImages.vendor.off;
      const markerImg = selectedVendorId.current === vendor_id ? markerImages.vendor.on : markerImages.vendor.off;
      vendorMarker.setImage(markerImg);

      // 입점사 이름
      const name = result.biz__name;
      vendorMarker.setTitle(name);
      vendorMarker.setPosition(vendorPosition);
      vendorMarker.setMap(vendorMap);
      vendorMarker.setClickable(true);

      // vendor marker 클릭시
      const markerClicked = () => {
        if (scriptedRef.current) {
          Object.keys(vendorMarkerObj.current).forEach((vendorId) => {
            const oldMarker = vendorMarkerObj.current[vendorId];
            oldMarker.setImage(markerImages.vendor.off);
          });

          vendorMap.panTo(vendorPosition);
          vendorMarker.setImage(markerImages.vendor.on);

          setSelectedVendor(() => ({
            location: { lat, lng, latitude: Number(lat), longitude: Number(lng) },
            name,
            vendor_id,
            biz_address1: result.biz__address1,
            biz_address2: result.biz__address2,
            shop_service_tag: result.shop__service_tag
          }));
        }
      };

      if (isNewMarker) {
        kakaoMaps.event.addListener(vendorMarker, 'click', markerClicked);
      }
      vendorMarkerObj.current[vendor_id] = vendorMarker;
      vendorMarkerObj.current = { ...vendorMarkerObj.current };
    });
  };

  // v1.1.3: 입점샵 QR 쿠폰 발급안내 레이어
  const [vendorQrCouponTarget, setVendorQrCouponTarget] = React.useState(null);
  const [showVendorQrCouponDialog, setShowVendorQrCouponDialog] = React.useState(false);
  const [vendorQrCouponId, setVendorQrCouponId] = React.useState(null);

  /**
   * 관심샵 등록 쿠폰발급 안내 레이어 띄우기
   *
   * @param vendor
   * @param couponId
   */
  const handleVendorQrCouponDownload = (vendor, couponId) => {
    setVendorQrCouponTarget(vendor); // 관심샵 이벤트 입점샵
    setVendorQrCouponId(couponId); // 발급된 쿠폰 아이디
    setShowVendorQrCouponDialog(true); // 쿠폰발급안내 레이어 열기
  };

  /**
   * 관심샵 등록 쿠폰발급 안내 레이어 닫기
   */
  const handleCloseVendorQrCouponDialog = () => {
    setShowVendorQrCouponDialog(false); // 쿠폰발급안내 레이어 닫기
    setVendorQrCouponId(null); // 발급된 쿠폰 아이디
    setVendorQrCouponTarget(null); // 관심샵 이벤트 입점샵
  };

  // render
  return (
    <Box id="vendor-map-container" position="relative">
      <Fab
        color="white"
        onClick={() => {
          navigate(-1);
        }}
        aria-label="위치설정 닫기"
        sx={{ position: 'absolute', top: '20px', left: '20px', boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
      >
        <Box component="img" src={ArrowBackImg} sx={{ height: '34px' }} />
      </Fab>
      {/* 지도영역 */}
      <Box
        onClick={() => {
          setSelectedVendor(null);
        }}
      >
        <Box component="div" id="vendor-map" ref={vendorMapDivRef} style={{ width, height }}>
          {vendorMap == null && (
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <WineBottleLoadingLottie />
              <Typography variant="caption">지도를 불러오는 중 입니다</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* 하단 액션영역 */}
      {vendorMap !== null && (
        // 선택된 샵이 있을시
        <BottomContainer>
          {geolocation?.access && (
            <Box
              position="absolute"
              top={-64}
              right={selectedVendor ? 0 : 20}
              left={selectedVendor && 280}
              mx={selectedVendor && 'auto'}
              width="52px"
            >
              <Box>
                <Fab
                  color="white"
                  onClick={(event) => {
                    event.stopPropagation();
                    console.debug('[핀지도] 사용자의 위치로 이동합니다.', geolocation.latitude, geolocation.longitude);
                    const pinPosition = new kakaoMaps.LatLng(geolocation.latitude, geolocation.longitude);
                    vendorMap.panTo(pinPosition);
                  }}
                  aria-label="위치설정 닫기"
                  sx={{ boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.16)' }}
                >
                  <CurrentLocationIcon color={theme.palette.text.primary} />
                </Fab>
              </Box>
            </Box>
          )}

          {selectedVendor && (
            <BottomContents>
              <Box>
                <VendorMapCard
                  selectedVendor={selectedVendor}
                  onMoveToVendor={(vendor) => {
                    setLastSelectedVendor(vendor);
                  }}
                  onVendorQrCouponDownloaded={(vendor, couponId) => {
                    handleVendorQrCouponDownload(vendor, couponId);
                  }}
                />
              </Box>
            </BottomContents>
          )}
        </BottomContainer>
      )}

      {/* v1.1.3: 입점샵 QR 프로모션 - 쿠폰발급 안내 레이어 */}
      {vendorQrCouponTarget && (
        <VendorQrDialog
          vendorId={vendorQrCouponTarget._id}
          vendorName={vendorQrCouponTarget.biz.name}
          couponId={vendorQrCouponId}
          open={showVendorQrCouponDialog}
          onConfirm={() => {
            // Amplitude Select Store
            logEvent.selectStore('관심샵 QR 쿠폰 발급완료', vendorQrCouponTarget._id, vendorQrCouponTarget.biz.name);
            navigate(`/vendor/d/${vendorQrCouponTarget._id}`, {
              state: { vendor: { _id: vendorQrCouponTarget._id, ...vendorQrCouponTarget, loaded: false } }
            });
            handleCloseVendorQrCouponDialog();
          }}
          onClose={() => {
            handleCloseVendorQrCouponDialog();
          }}
        />
      )}
    </Box>
  );
}

VendorListMap.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default VendorListMap;

/* eslint-disable react/prop-types */
const BottomContainer = React.memo(({ children }) => (
  <Box position="fixed" bottom="34px" left={0} width="100vw" zIndex={1}>
    {children}
  </Box>
));

/* eslint-disable react/prop-types */
const BottomContents = React.memo(({ children }) => (
  <Box
    height={BOTTOM_COMPONENT_HEIGHT}
    display="flex"
    position="relative"
    flexDirection="column"
    className="map-bottom-component"
    mx="auto"
    bgcolor="background.paper"
    boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.16)"
    paddingX="14px"
    paddingY="16px"
    width="100%"
    maxWidth={335}
    borderRadius="14px"
  >
    {children}
  </Box>
));
