import React from 'react';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import * as _ from 'lodash';

// project imports
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useScriptRef } from 'hooks';
import { Calculator } from 'utils/bourgogne-tools';
import { WoAlert } from 'utils/kmwine-alerts';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';
import { OrderStateChip } from '../../index';
import { ProductCurrency, ProductName, ProductPrice, SpaceBetweenBox } from 'components';
import { CoBuyChip, ProductImage } from 'components/v2';
import { OrderItemActionButtons } from '../../OrderComponents';
import { cancelExchangeRequest, cancelRefundRequest, confirmOrderItem } from 'services/OrderService';

// material-ui
import { Box, CardContent, Chip, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const IMG_WIDTH = 75; // product thumbnail img width
const IMG_HEIGHT = 104; // product thumbnail img height

/**
 * 마이 > 주문 내역의 상품의 상태를 그룹화하여 표시
 * @param orderItem
 * @returns {JSX.Element}
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function OrderStateGroup({ groupState, groupNm, order, orderItemAction, detailPdataInfo }) {
  const scriptedRef = useScriptRef();
  const location = useLocation();
  const navigate = useNavigate();
  const globalDispatch = useDispatch();

  const isTicket = React.useMemo(() => {
    return order.order_products[Object.keys(order.order_products)[0]].items[0]?.pdata.category === 'ticket';
  }, [order]);

  // 예약 프로모션인지 확인
  const isPromoReserve = React.useMemo(() => {
    if (order.promotion) {
      return order.promotion.pickup.state === 'none';
    }
    return false;
  }, [order]);

  const theme = useTheme();
  // 화면 가로 사이즈 크기 정보
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(350));

  // 주문 아이디, 주문상태
  const { oid, state: orderState } = React.useMemo(() => order, [order]);

  // 주문상품 배열
  const orderProducts = React.useMemo(() => {
    let result = [];

    const { order_products, cobuy_state = 'NONE' } = order;

    // 공동구매가 아닌 상품
    if (cobuy_state === 'NONE') {
      // 교환/반품
      if (groupState === 'EXCRTN') {
        // 교환/반품 상품만 추출
        if (order_products.EXC_WAIT) result.push(...order_products.EXC_WAIT.items); // 교환대기
        if (order_products.RTN_WAIT) result.push(...order_products.RTN_WAIT.items); // 반품대기
        if (order_products.RTN_OK) result.push(...order_products.RTN_OK.items); // 반품완료

        if (order_products.REQ_EXC) result.push(...order_products.REQ_EXC.items); // 교환요청
        if (order_products.REQ_RTN) result.push(...order_products.REQ_RTN.items); // 반품요청
      }
      // 픽업대기 = 승인 & 부분승인
      else if (groupState === 'REQ_OK') {
        if (order_products.REQ_OK) result.push(...order_products.REQ_OK.items); // 승인
        if (order_products.REQ_PART_OK) result.push(...order_products.REQ_PART_OK.items); // 부분승인
      }
      // 픽업완료 = 픽업완료 & 교환 거절 & 반품 거절
      else if (groupState === 'PICK_OK') {
        if (order_products.PICK_OK) result.push(...order_products.PICK_OK.items); // 픽업완료
        if (order_products.EXC_NO) result.push(...order_products.EXC_NO.items); // 교환거절
        if (order_products.RTN_NO) result.push(...order_products.RTN_NO.items); // 반품거절
      }

      // 주문취소 = 주문취소 & 주문거절
      else if (groupState === 'CANCEL') {
        // 주문 병수(origin_quantity)와 주문 승인된 병수(quantity)가 다른 상품 찾기
        const states = Object.keys(order_products);
        for (let i = 0; i < states.length; i++) {
          const key = states[i];

          // 주문취소, 주문 거절된 상품은 무조건 표현
          if (key === 'CANCEL' || key === 'REQ_NO') {
            result.push(...order_products[key].items);
            continue;
          }

          // 주문 취소, 주문 거절 외의 상품 중 origin_quantity와 quantity가 일치하지 않는 상품이 부분취소된 상품
          for (let j = 0; j < order_products[key].items.length; j++) {
            const item = order_products[key].items[j];

            const originQuantity = item.origin_quantity ?? item.quantity;

            if (originQuantity === item.quantity) {
              continue;
            }

            result.push(item);
          }
        }
      } else {
        result = order.order_products[groupState]?.items ?? [];
      }
    }
    // [PROD-308] 공동구매상품
    else if (groupState === 'CO_BUY') {
      const coBuyId: string = order.co_buy?._id ?? null;
      if (coBuyId) {
        // 공동구매 주문상품
        const states = Object.keys(order_products);
        for (let i = 0; i < states.length; i++) {
          const key = states[i];
          result.push(...order_products[key].items);
        }
      } else {
        /* DO NOTHING */
      }
    }

    // 와인상품 한글명 순으로 재정렬
    if (result.length > 0) result = _.sortBy(result, ['name.ko']);

    return result;
  }, [groupState, order]);

  // 주문단위 기능버튼
  const handleOrderItemAction = (e, itemAction, orderItem, order) => {
    // 교환반품 신청
    if (itemAction === 'order.item.excrtn') {
      navigate(`/my/order/${oid}/excrtn`, { state: { order, defaultChecked: [orderItem.id] } });
    }

    // 교환 신청 취소
    else if (itemAction === 'order.item.cancel-exchange') {
      requestExchangeCancel(orderItem);
    }

    // 반품 신청 취소
    else if (itemAction === 'order.item.cancel-return') {
      requestRefundCancel(orderItem);
    }

    // 구매확정
    else if (itemAction === 'order.item.confirm') {
      // 구매확정 API요청
      WoAlert.fire({
        title: (
          <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
            구매확정
          </Typography>
        ),
        html: `${
          isTicket
            ? "<div style='line-height: 1.5rem; text-align: left'>구매확정을 진행하시겠습니까?</div>"
            : "<div style='line-height: 1.5rem; text-align: left'>구매확정 후에는 교환/반품 신청이 불가능합니다.<br/>구매확정을 진행하시겠습니까?</div>"
        }`,
        allowOutsideClick: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '구매확정',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        },
        preConfirm: async () => {
          WoAlert.showLoading();
          WoAlert.getCancelButton().setAttribute('style', 'display:none');

          const response = await confirmOrderItem(oid, [orderItem.id])
            .then((res) => {
              if (res.data.result.code !== 0) throw new Error(res.data.result?.msg ?? '구매확정 실패');
              return res;
            })
            .catch((error) => {
              try {
                httpsCallable(
                  getFirebaseFunctions(),
                  'call-cdm-clo-error'
                )({
                  code: CLO_CODE.IMPORTANT_CONTENT_ERROR,
                  title: `구매확정 처리중 오류 발생 [error=${error.name}]`,
                  msg: `주문번호=${oid}, ${error.message}`,
                  which: `${location.pathname}${location.search}`
                })
                  .then(console.log)
                  .catch(console.error);
              } catch (e) {
                /* DO NOTHING */
              }
              return { error };
            });

          if (!scriptedRef.current) {
            console.warn('[OrderStateGroup][handleOrderItemAction][order.item.confirm] Unmounted Component');
            return;
          }

          // 구매확정 요청실패
          if (response.error) {
            await WoAlert.fire(`구매확정 처리 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
            return;
          }

          await waait(2000);

          return response.data;
        }
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          orderItemAction('refresh', orderItem);

          // 리뷰를 작성하시겠어요?
          if (orderItem.pdata?.category !== 'ticket') {
            reviewConfirm(orderItem, oid);
          }
        }
      });
    }
    // 리뷰 작성 화면으로 이동
    else if (itemAction === 'order.item.write-review') {
      const { pdata, id: orderProductId, vintage } = orderItem;
      const { oid } = order;

      if (!pdata?._id || !order || !orderProductId) {
        WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'error');
        return;
      }

      navigate(`/my/review/${pdata._id}/write`, { state: { oid, orderProductId, vintage } });
    }
    // 리뷰 상세 화면으로 이동
    else if (itemAction === 'order.item.show-review') {
      const review_id = orderItem.review_id ?? orderItem.review.id;
      navigate(`/my/review/${review_id}`);
      // navigate(`/mall/wine/${pdata_id}/review/${review_id}`);
    } else {
      console.log('[1KMWINE] 정의되지 않은 itemAction.', itemAction);
    }
  };

  // 교환 신청 취소 요청
  const requestExchangeCancel = (orderItem) => {
    WoAlert.fire({
      title: (
        <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
          교환 신청 취소
        </Typography>
      ),
      html: `<div style='line-height: 1.5rem; text-align: left'>교환 신청을 취소할까요?<br/>주문 상태는 픽업 완료 상태로 돌아갑니다.</div>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '취소하기',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      },
      preConfirm: async () => {
        WoAlert.showLoading();
        const response = await cancelExchangeRequest(oid, [orderItem.id], '')
          .then((response) => {
            const { result, data } = response.data;
            if (result.code !== 0) throw new Error(response.data.result.msg);
            return data;
          })
          .catch((error) => {
            console.error('[1kmwine] 교환 신청 취소 요청 실패', error);
            return { error };
          });

        if (!scriptedRef.current) {
          console.warn('[OrderStateGroup][requestExchangeCancel] Unmounted Component');
          return;
        }

        await waait(2000);
        await orderItemAction('refresh', orderItem);

        return response;
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (result.value.error) {
          WoAlert.fire(`교환 신청 취소 요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
        }
        WoAlert.hideLoading();
        // Global snackbar 띄우기.
        globalDispatch(
          openSnackbar({
            open: true,
            message: `교환 신청이 취소되었습니다.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true,
            autoHideDuration: 5000
          })
        );
      }
    });
  };

  // 반품취소 요청
  const requestRefundCancel = (orderItem) => {
    WoAlert.fire({
      title: (
        <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
          반품 신청 취소
        </Typography>
      ),
      html: `<div style='line-height: 1.5rem; text-align: left'>반품 신청을 취소할까요?<br/>주문 상태는 픽업 완료 상태로 돌아갑니다.</div>`,
      allowOutsideClick: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '취소하기',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      },
      preConfirm: async () => {
        WoAlert.showLoading();
        WoAlert.getCancelButton().setAttribute('style', 'display:none');
        const response = await cancelRefundRequest(oid, [orderItem.id], '')
          .then((response) => {
            const { result, data } = response.data;
            if (result.code !== 0) {
              throw new Error(response);
            }
            return data;
          })
          .catch((error) => {
            console.error('[1kmwine] 반품 신청 취소 요청 실패', error);
            return { error };
          });

        if (!scriptedRef.current) {
          console.warn('[OrderStateGroup][requestRefundCancel] Unmounted Component');
          return;
        }

        await waait(2000);
        await orderItemAction('refresh', orderItem);

        return response;
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (result.value.error) {
          WoAlert.fire(`반품 신청 취소 요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
        }
        WoAlert.hideLoading();
        // Global snackbar 띄우기.
        globalDispatch(
          openSnackbar({
            open: true,
            message: `반품 신청이 취소되었습니다.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true,
            autoHideDuration: 5000
          })
        );
      }
    });
  };

  // 구매확정 후 리뷰확인
  const reviewConfirm = (orderItem, oid) => {
    WoAlert.fire({
      title: (
        <Typography component="div" fontSize="18px" fontWeight="bold" textAlign="left">
          구매 확정 완료
        </Typography>
      ),
      html: (
        <Box lineHeight="1.5rem" textAlign="left">
          구매 확정이 완료되었습니다.
          <br />
          와인에 대한 솔직한 리뷰를 작성 해주세요.
        </Box>
      ),
      showCancelButton: true,
      confirmButtonText: '리뷰쓰기',
      cancelButtonText: '다음에 쓰기',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { pdata, id: orderProductId, vintage } = orderItem;

        if (!pdata?._id || !oid || !orderProductId) {
          WoAlert.fire('', '잠시 후 다시 시도해주세요.', 'error');
          return;
        }
        navigate(`/my/review/${pdata._id}/write`, { state: { oid, orderProductId, vintage } });
      } else if (result.isDismissed) {
        globalDispatch(
          openSnackbar({
            open: true,
            message: `리뷰는 '마이>리뷰'에서 작성 할 수 있어요.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true,
            autoHideDuration: 5000
          })
        );
      }
    });
  };

  // 교환거절/반품거절 일시
  const rejectDateTimeStr = React.useCallback((date) => {
    return (
      <Typography variant="caption" fontSize={isSmallScreen ? 10 : 13} lineHeight="normal" ml="2px" fontWeight="lighter">
        {moment(date).format('YYYY.MM.DD(ddd)')}
      </Typography>
    );
  }, []);

  // render
  return (
    orderProducts.length > 0 && (
      <Box mt="20px">
        <StateGroupHeader groupState={groupState} groupNm={groupNm} order={order} />

        {/* 주문 상품 표현 */}
        {orderProducts.map((orderItem, i) => {
          const { capacity, price, quantity: _quantity, origin_quantity: _origin_quantity, name, pdata, state, state_at } = orderItem;

          // 부분취소 개발 전 데이터에는 origin_quantity가 없을 수 있음
          const origin_quantity = _origin_quantity !== null ? _origin_quantity : _quantity;
          let quantity = _quantity;
          // 주문의 상태가 전체취소(CANCEL)일 경우 수량을 취소수량으로 변경

          // CANCEL <- 주문취소 & 주문 거절
          if (groupState === 'CANCEL') {
            if (orderItem.state === 'CANCEL') {
              quantity = origin_quantity;
            } else {
              quantity = origin_quantity !== _quantity ? origin_quantity - _quantity : origin_quantity;
            }
          }

          // 교환/반품
          else if (groupState === 'EXCRTN') {
            /* DO NOTHING */
          }

          if (!orderItem.origin_quantity) {
            quantity = orderItem.quantity;
          }

          // 주문상품 상태명
          const { state_nm = '' } = order.order_products[state] ?? {};

          // 티켓상품인가?
          const isTicket = orderItem.pdata?.category === 'ticket';

          // 공동구매 상품인가?
          const isCoBuy = order.cobuy_state !== 'NONE';

          return (
            <CardContent key={`order-item-${i}-row`} sx={{ p: 0, mt: '16px', '&:last-child': { pb: 0 } }}>
              <Box display="flex" flexDirection="row">
                {/* 상품 이미지 */}
                <Box
                  mr={1.25}
                  width={IMG_WIDTH}
                  height={IMG_HEIGHT}
                  onClick={() => {
                    if (!isTicket) {
                      detailPdataInfo(pdata?._id);
                      // openWineDetail(pdata);
                    }
                  }}
                >
                  <ProductImage category={pdata?.category} width={IMG_WIDTH} height={IMG_HEIGHT} src={pdata.bottle_img} alt="상품 이미지" />
                </Box>

                {/* 상품 정보 */}
                <Box flexGrow={1} pt={1} display="flex" flexDirection="column">
                  <Box>
                    {/* 상품의 상태가 교환거절(EXC_NO) 또는 반품거절(RTN_NO)일 경우 */}
                    {!isCoBuy && groupState !== 'CANCEL' && (state === 'EXC_NO' || state === 'RTN_NO') && (
                      <Box display="flex" alignItems="center" mb="4px">
                        <OrderStateChip label={state_nm === '반품거절' ? '와인샵 반품취소' : state_nm} sx={{ mr: '8px', mt: '-2px' }} />
                        {rejectDateTimeStr(state_at)}
                      </Box>
                    )}

                    {/* 상품명 (한글) - 최대 2줄 표현 */}
                    <ProductName component="div">
                      <Box display="flex">
                        {/* 상품의 상태가 교환요청(REQ_EXC) 또는 교환대기(EXC_WAIT) 또는 반품요청(REQ_RTN) 또는 반품완료(RTN_OK) */}
                        {!isCoBuy ? (
                          groupState !== 'CANCEL' &&
                          (state === 'REQ_EXC' ||
                            state === 'EXC_WAIT' ||
                            state === 'REQ_RTN' ||
                            state === 'RTN_OK' ||
                            state === 'RTN_WAIT') && <OrderStateChip label={state_nm} sx={{ mr: '4px', mt: '-2px' }} />
                        ) : (
                          <CoBuyChip sx={{ mr: '4px', mt: '-2px' }} />
                        )}
                        {isPromoReserve && (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            ml="4px"
                            borderRadius="2px"
                            width="43px"
                            height="16px"
                            sx={{ bgcolor: 'rgba(60, 174, 71, 0.12)' }}
                          >
                            <Typography fontSize="10px" fontWeight="700" color="#3CAE47">
                              예약주문
                            </Typography>
                          </Box>
                        )}
                        {name.ko}
                      </Box>
                    </ProductName>

                    {/* 빈티지 / 용량 */}
                    {!isTicket && (
                      <Box mt={1}>
                        <Stack
                          direction="row"
                          spacing="4px"
                          divider={
                            <Box display="flex" alignItems="center" pb="1px">
                              <Box height="9px" width="1px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
                            </Box>
                          }
                          sx={{ fontSize: 11, fontWeight: 300 }}
                        >
                          {/* 빈티지가 NV일 경우 표시 안함 */}
                          {orderItem?.vintage && orderItem.vintage !== 'NV' && <span>{`${orderItem.vintage}년`}</span>}
                          <span>{Calculator.formatCapacity(capacity)}</span>
                        </Stack>
                      </Box>
                    )}
                  </Box>

                  {/* 상품 금액/수량 정보 */}
                  <Box display="flex">
                    <Box display="inline-flex" width={`calc(100% - 46px)`} maxWidth={`calc(100% - 46px)`} pr="7px">
                      <Box display="flex" alignItems="center">
                        {/* 상품 금액 */}
                        <ProductPrice>{((price?.original >> 0) * quantity).toLocaleString()}</ProductPrice>

                        <ProductCurrency multiple={false} pl="1px" />

                        {/* 상품 수량 */}
                        <Typography component="span" variant="caption" fontSize={11} color="#5A4D69" sx={{ ml: '0.5rem' }}>
                          수량 : {quantity?.toLocaleString()}개
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* 주문상품단위 기능 버튼 모음 (parameter로 넘어온 상품그룹의 상태(type)가 픽업완료(PICK_OK) 또는 구매확정(CONFIRM) 또는 교환/반품(EXCRTN) 일 경우 */}
              {groupState !== 'CANCEL' && (orderState === 'PICK_OK' || orderState === 'CONFIRM' || orderState === 'EXCRTN') && (
                <OrderItemActionButtons
                  order={order}
                  orderItem={orderItem}
                  handleClick={(e, itemAction) => handleOrderItemAction(e, itemAction, orderItem, order)}
                />
              )}
            </CardContent>
          );
        })}
      </Box>
    )
  );
}

export default OrderStateGroup;

OrderStateGroup.propTypes = {
  groupState: PropTypes.string.isRequired,
  groupNm: PropTypes.string,
  order: PropTypes.object.isRequired,
  orderItemAction: PropTypes.func.isRequired,
  detailPdataInfo: PropTypes.func
};

/* eslint-disable react/prop-types */
const StateGroupHeader = React.memo(({ groupState, groupNm, order }) => {
  const isTicket = React.useMemo(() => {
    return order.order_products[Object.keys(order.order_products)[0]].items[0]?.pdata.category === 'ticket';
  }, [order]);

  // 예약 프로모션인지 확인
  const isPromoReserve = React.useMemo(() => {
    if (order.promotion) {
      return order.promotion.pickup.state === 'none';
    }
    return false;
  }, [order]);

  const theme = useTheme();
  // 화면 가로 사이즈 크기 정보
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(350));

  // 픽업일시
  const pickupDateTimeStr = React.useMemo(() => {
    if (!order?.pickup_date || !order?.pickup_time) return null;
    if (order.co_buy?._id) return null; // [PROD-308] 공동구매 상품은 표현안함
    if (order?.promotion?.pickup?.state === 'none') return null; // 프로모션 예약상품일경우 표연안함

    const { pickup_date, pickup_time } = order;

    return (
      <Typography variant="caption" fontSize={isSmallScreen ? 10 : 13} lineHeight="normal" ml="2px" fontWeight="lighter">
        {isTicket ? '주문일시' : '픽업일시'} &nbsp;
        {moment(`${pickup_date} ${pickup_time}`, 'YYYYMMDD HH:mm').format('YYYY.MM.DD(ddd) a hh:mm')}
      </Typography>
    );
  }, [groupState, isSmallScreen, order?.pickup_date]);

  // 주문상태 라벨
  const label = React.useMemo(() => {
    const { cobuy_state = 'NONE' } = order;

    // 예약판매 프로모션 주문상태 표현
    if (isPromoReserve && order.state !== 'PICK_OK') {
      if (order.state === 'CANCEL') return '주문취소';
      if (order.state === 'PICK_OK') return '픽업완료';
      if (order.promotion.vendor_r_state === 'OK') return '픽업대기';
      if (new Date(order.promotion.period.start_dt) < new Date() && new Date() < new Date(order.promotion.period.end_dt))
        return '예약주문 진행 중';
      if (new Date(order.promotion.period.end_dt) < new Date()) return '주문 마감(입고대기)';
      return '프로모션';
    }

    if (groupState === 'CANCEL') {
      return '주문취소';
    }

    // 반품거절이나 교환거절시
    if (order.state === 'PICK_OK' && ('EXC_NO' in order.order_products || 'RTN_NO' in order.order_products)) {
      return '픽업완료';
    }

    if (cobuy_state === 'NONE') {
      // keymedi2408 - 키메디 공동구매 하드코딩. 지우면 안됨.
      if (order.from === 'keymedi2408') {
        console.log('### order.state: ', order.state, groupNm);
        if (order.state === 'REQ' || order.state === 'REQ_OK') {
          return '키메디 공동구매';
        }
      }
      // 공동구매 아닌 상품은 기본 그룹명
      return groupNm;
    }

    /*
     * 이 아래로는 공동구매 상태표현
     ******************************************************************
     */

    // 공동구매 실패 => 개수 미달성 또는 관리자에서 취소
    if (cobuy_state === 'FAIL') return '주문취소';

    // 사용자가 공동구매 주문 취소 또는 공동구매 실패
    if (order.state === 'CANCEL') return '주문취소';

    let str = `잘못된 주문정보`;

    if (cobuy_state === 'WAIT') {
      str = '공동구매 진행 중';
    }
    // 공동구매 픽업완료(|구매확정)
    else if (order.state === 'PICK_OK') {
      str = order.order_products.CONFIRM ? '구매확정' : '픽업완료';
    }
    // 공동구매 주문 확정
    else if (cobuy_state === 'SUCC') {
      str = '공동구매 주문 확정';
      try {
        const { vendor: coBuyVendor } = order.co_buy;
        // 공동구매 상품이 입점샵에 입고완료
        if (coBuyVendor.state === 'OK') {
          str = '픽업대기';
        }
      } catch (e) {
        console.warn('[주문목록] 주문상태표현 오류', e);
      }
    }

    // todo 픽업완료

    // 와인샵의 주문거절(기술적으로 불가능한 상태)
    else if (order.state === 'REQ_NO') {
      str = '주문취소';
      console.warn(`잘못된 주문정보#OSG
- order.oid = ${order.oid}
- order.state = ${order.state}
- order.cobuy_state = ${cobuy_state}
- order.co_buy.vendor.state = ${order.co_buy?.vendor.state}
`);
    } else {
      console.warn(`처리해주세요#38290
- order.oid = ${order.oid}
- order.state = ${order.state}
- order.cobuy_state = ${cobuy_state}
- order.co_buy.vendor.state = ${order.co_buy?.vendor.state}
`);
    }

    return str;
  }, [order, groupNm]);

  // 공동구매 안내 텍스트
  const coBuyInfoText = React.useMemo(() => {
    const { cobuy_state = 'NONE' } = order;

    // 공동구매 아닌 상품은 표현 없음
    if (cobuy_state === 'NONE') return null;

    // 사용자가 공동구매 주문 취소
    if (order.state === 'CANCEL' && cobuy_state !== 'FAIL') return null;

    // 픽업완료
    if (order.state === 'PICK_OK') return null;

    let infoText = null;

    // 공동구매 실패
    if (cobuy_state === 'FAIL') {
      return '주문 수량 미달성으로 주문이 자동 취소되었어요';
    }

    // 공동구매 종료일시
    const coBuySaleEndMmt = moment(order.co_buy.sale_end_dt);
    const now = moment(); // 현재시간

    // 공동구매 진행 중
    if (order.cobuy_state === 'WAIT') {
      const format = now.year() === coBuySaleEndMmt.year() ? 'M.D(ddd)' : 'YY.M.D(ddd)';
      infoText = `공동 구매 주문 진행 중이에요 [마감일 ${coBuySaleEndMmt.format(format)}]`;
    }
    // 공동구매 주문 확정
    else if (order.cobuy_state === 'SUCC') {
      const { vendor: coBuyVendor } = order.co_buy;

      // 공동구매상품이 입점샵에 입고되기 전
      if (coBuyVendor.state === 'WAIT' || coBuyVendor.state === 'CONFIRM') {
        // 와인샵 배송완료일시
        const dlvrEndMmt = moment(order.co_buy.delivery_end_dt);
        const format = now.year() === dlvrEndMmt.year() ? 'M.D(ddd)' : 'YY.M.D(ddd)';
        infoText = `${dlvrEndMmt.format(format)}까지 픽업샵으로 상품을 배송해 드릴게요`;
      } else if (coBuyVendor.state === 'OK') {
        const pickEndMmt = moment(order.co_buy.pick_end_dt);
        const format = now.year() === pickEndMmt.year() ? 'M.D(ddd)' : 'YY.M.D(ddd)';
        // 픽업안내 일시
        infoText = `상품 입고 완료! ${pickEndMmt.format(format)}까지 픽업해주세요.`;
      }
    } else {
      console.warn('!$처리필요$!', order);
    }

    // console.log('#order.co_buy: ', order.co_buy);
    return infoText;
  }, [order.state, order.cobuy_state, order?.co_buy]);

  // 프로모션 상품일 경우
  const promotionText = React.useMemo(() => {
    if (!isPromoReserve) return null;

    if (order.state === 'CANCEL' || order.state === 'PICK_OK' || order.state === 'CONFIRM') return null;

    if (new Date(order.promotion.period.start_dt) < new Date() && new Date() < new Date(order.promotion.period.end_dt)) {
      return `예약 주문 진행 중이에요 [마감일 ${moment(order.promotion.period.end_dt).format('MM.DD(dd)')}] `;
    }

    if (order.promotion.vendor_r_state === 'OK') {
      return `상품 입고 완료! ${moment(order.promotion.pickup.end_dt).format('MM.DD(dd)')}까지 픽업해주세요`;
    }

    if (new Date() > new Date(order.promotion.period.end_dt)) {
      return '상품 입고 후 픽업 일정을 안내해 드릴게요';
    }
  }, [order]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {/* 주문 상태표현 */}
        <SpaceBetweenBox mb="9px" sx={{ mb: 0, mr: '8px' }}>
          <Box display="flex" alignItems="center">
            <Chip
              label={label}
              size="small"
              sx={(theme) => ({
                minWidth: '75px',
                maxWidth: '110 px',
                padding: '6px 0 5px',
                fontSize: 11,
                fontWeight: 800,
                lineHeight: 'normal',
                color: '#ffffff',
                bgcolor: theme.palette.brand.main
              })}
            />
          </Box>
        </SpaceBetweenBox>
        {/* 픽업일시 */}
        {pickupDateTimeStr}
      </Box>
      {coBuyInfoText && (
        <Box mt="16px" mb="-4px" color="#F5715F" pl="4px">
          <Typography fontSize={12} lineHeight={1} fontWeight="bold" sx={{ opacity: 0.7 }}>
            {coBuyInfoText}
          </Typography>
        </Box>
      )}

      {isPromoReserve && (
        <Box mt="16px" mb="-4px" color="rgba(60, 174, 71, 0.70)" pl="4px">
          <Typography fontSize={12} lineHeight={1} fontWeight="bold" sx={{ opacity: 0.7 }}>
            {promotionText}
          </Typography>
        </Box>
      )}
    </Box>
  );
});

const waait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
