import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { deleteDoc, doc, getDoc } from 'firebase/firestore';

// import { useDispatch } from 'store';
// import { openSnackbar } from 'store/slices/snackbar';
import { useAmplitude, useAuth, useScriptRef } from 'hooks';
import { getFirestore } from 'utils/firebase-tools';
import { addWishItem } from 'services/UserService';
import { HeartIcon } from 'components/icons';
import LikeButtonLottie from 'components/spinner/LikeButtonLottie';

// material-ui
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import heartbeatLoaderJson from 'assets/lottie/heartbeat-loader.json';

// amplitude
import { amplitude } from 'index';

/**
 * 찜하기 버튼
 * @param pdataId
 * @param pdataName
 * @param onWishChange
 * @param other
 * @returns {JSX.Element}
 * @constructor
 */
function WishButton({ pdataId, pdataName, onWishChange = () => false, ...other }) {
  const scriptedRef = useScriptRef();
  const containerRef = React.useRef(null);
  const [isWish, setIsWish] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { logEvent } = useAmplitude();
  const location = useLocation();
  const { user } = useAuth();
  // const globalDispatch = useDispatch();

  React.useEffect(() => {
    try {
      containerRef.current.innerHTML = '';
    } catch (e) {
      /* DO NOTHING */
    }
    lottie.loadAnimation({
      container: containerRef.current,
      loop: true,
      autoplay: true,
      animationData: heartbeatLoaderJson,
      rendererSettings: { clearCanvas: true }
    });
  }, []);

  React.useLayoutEffect(() => {
    getDoc(doc(getFirestore(), `member/${user._id}/wishes`, `${pdataId}-${user._id}`))
      .then((docSnapshot) => {
        if (scriptedRef.current) {
          setIsWish(docSnapshot.exists());
        }
      })
      .catch((e) => {
        console.error(`와인[pdata.id=${pdataId}]의 wish 여부 조회 중 오류가 발생했습니다.`, e);
      });
  }, [pdataId]);

  const toggleWish = () => {
    // console.log(`현재 wish 여부 : ${isWish}`);
    if (isWish) {
      console.debug(`와인[pdata.id=${pdataId}]을 찜목록에서 제외.`);
      setIsWish(false);
      // globalDispatch(
      //   openSnackbar({
      //     open: true,
      //     message: '상품을 위시 리스트에서 제외합니다.',
      //     variant: 'alert',
      //     alert: { color: 'success' },
      //     close: true,
      //     autoHideDuration: 2000
      //   })
      // );

      deleteDoc(doc(getFirestore(), `member/${user._id}/wishes`, `${pdataId}-${user._id}`))
        .then((r) => {
          console.debug('찜목록 제외 완료', r);

          // Amplitude number of wishlist wines user property
          const identify = new amplitude.Identify().add('number of wishlist wines', -1);
          amplitude.getInstance().identify(identify);

          if (scriptedRef.current) {
            setIsWish(false);
            onWishChange(false);
          }
        })
        .finally(() => {
          if (scriptedRef.current) {
            setLoading(false);
          }
        });
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      console.debug(`와인[pdata.id=${pdataId}]을 찜목록에 추가.`);
      const currentPathname = location.pathname;

      setIsWish(true);
      addWishItem(pdataId).then((r) => {
        // 주문 가능 와인샵
        if (currentPathname.includes('/mall/wine')) {
          logEvent.addToWishList('주문 가능 와인샵', pdataId, pdataName);
        }
        // 와인샵 상세페이지
        else if (currentPathname.includes('/vendor/d')) {
          logEvent.addToWishList('와인샵 상세', pdataId, pdataName);
        }
        // 실시간 구매 현황 페이지
        else if (currentPathname.includes('/home/live')) {
          logEvent.addToWishList('실시간 구매 현황', pdataId, pdataName);
        }
        // 이벤트 페이지
        else if (currentPathname.includes('/my/event')) {
          logEvent.addToWishList('이벤트', pdataId, pdataName);
        }
        // 홈 리뷰 목록 페이지
        else if (currentPathname === '/home/main-reviews') {
          logEvent.addToWishList('리뷰 목록', pdataId, pdataName);
        }
        // 리뷰 상세 페이지
        else if (currentPathname.includes('/home/main-review')) {
          logEvent.addToWishList('리뷰 상세', pdataId, pdataName);
        }
        // 큐레이션 카드 페이지
        else if (currentPathname.includes('/home/curation-card')) {
          logEvent.addToWishList('큐레이션 카드', pdataId, pdataName);
        }
        // 매거진 페이지
        else if (currentPathname.includes('/magazine')) {
          logEvent.addToWishList('매거진', pdataId, pdataName);
        }
        // 주문하기 페이지
        else if (currentPathname === '/order/confirm') {
          logEvent.addToWishList('주문하기', pdataId, pdataName);
        }
        // 와인픽스 특가 페이지
        else if (currentPathname === '/pos/campaign/list') {
          logEvent.addToWishList('와인픽스 특가', pdataId, pdataName);
        }
        // 와인픽스 추석세트 페이지
        else if (currentPathname === '/pos/t-giving') {
          logEvent.addToWishList('와인픽스 2024 추석세트', pdataId, pdataName);
        }

        if (scriptedRef.current) {
          console.debug('찜목록 추가 완료. response=', r);

          setIsWish(true);
          onWishChange(true);

          // globalDispatch(
          //   openSnackbar({
          //     open: true,
          //     message: '상품을 위시 리스트에 추가합니다.',
          //     variant: 'alert',
          //     alert: { color: 'success' },
          //     close: true,
          //     autoHideDuration: 2000
          //   })
          // );
        }
      });
    }
  };

  return isWish == null ? (
    <Box ref={containerRef} width={30} height={30} sx={{ ...(other.sx ?? {}) }} />
  ) : (
    <IconButtonBase aria-label="와인 찜하기" onClick={toggleWish} {...other}>
      {loading && <LikeButtonLottie />}
      <HeartIcon filled={isWish} />
    </IconButtonBase>
  );
}

WishButton.propTypes = {
  pdataId: PropTypes.string.isRequired,
  pdataName: PropTypes.string.isRequired,
  onWishChange: PropTypes.func
};

export default WishButton;

const IconButtonBase = styled(IconButton)({
  color: '#fb6464',
  padding: 0
});
