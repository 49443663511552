import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { collection, doc, documentId, getDoc, getDocs, query, where } from 'firebase/firestore';

// project imports
import { useAuth, useScriptRef } from 'hooks';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import BackHeader from 'layouts/Main/BackHeader';
import { getFirestore } from 'utils/firebase-tools';
import { WoAlert } from 'utils/kmwine-alerts';

import { openSnackbar } from 'store/slices/snackbar';

// components
import OrderCard from './components/OrderCard';

// material-ui
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';

// 교환/반품 신청
import { calculateRefundCommission, requestExcRtn } from 'services/OrderService';
import { WineBottleLoadingLottie } from '../../../../components';

/**
 * 교환/반품 요청화면
 *
 * @returns {JSX.Element}
 * @constructor
 */
function OrderExcRtn() {
  // 사용자 정보
  const { user } = useAuth();

  const globalDispatch = useDispatch();

  const navigate = useNavigate();
  const scriptedRef = useScriptRef();
  // const location = useLocation();

  // 주문번호
  const { orderNo } = useParams(); // 주문번호(oid)

  // 주문정보
  const [order, setOrder] = React.useState({ order: { loaded: false } });

  // 요청 중
  const [requesting, setRequesting] = React.useState(false);

  // 교환/반품 타입
  const [excrtnType, setExcrtnType] = React.useState(null);

  const handleExcRtnTypeChange = (event) => {
    const { value: type } = event.target;
    console.debug(`[교환/반품] '방법 선택' 라디오 선택됨: `, type);
    setExcrtnType(type);
  };

  // 교환/반품 사유
  const [reasonType, setReasonType] = React.useState(null);

  const handleReasonType = (event) => {
    setReasonType(event.target.value);
  };

  // 교환/반품 사유 상세설명
  const [reason, setReason] = React.useState('');

  // 구매시 사용된 쿠폰 정보
  // const [usedCoupon, setUsedCoupon] = React.useState({
  //   loaded: false,
  //   error: false,
  //   datas: []
  // });

  // 반품 가능여부
  const [returnable, setReturnable] = React.useState(false);

  // 반품 체크박스 라벨
  const returnCheckLabel = React.useMemo(() => {
    let label = <Typography variant="body1">반품</Typography>;

    if (!returnable) {
      // 나라포스 캠페인 할인이 적용된 주문
      const isNaraCampaignOrder = order.orderCampaigns?.length > 0;
      // 쿠폰이 적용된 주문
      const couponUsed = order.coupons?.length > 0;
      if (isNaraCampaignOrder) {
        label = (
          <Typography variant="body1" color={(theme) => theme.palette.text.disabled}>
            반품 <small>(반품이 불가능한 주문입니다 [주문할인 적용])</small>
          </Typography>
        );
      } else if (couponUsed) {
        label = (
          <Typography variant="body1" color={(theme) => theme.palette.text.disabled}>
            반품 <small>(반품이 불가능한 주문입니다{order.coupons?.length > 0 ? ' [쿠폰사용]' : ''})</small>
          </Typography>
        );
      }
    }
    return label;
  }, [returnable, order.coupons?.length, order.orderCampaigns?.length]);

  React.useLayoutEffect(() => {
    if (order.loaded && orderNo === order.oid) {
      // 나라포스 캠페인 할인이 적용된 주문
      const isNaraCampaignOrder = order.orderCampaigns?.length > 0;
      // 쿠폰이 적용된 주문
      const couponUsed = order.coupons?.length > 0;

      // 반품 불가 - 결제시 쿠폰을 사용했을 경우 반품이 불가능합니다.
      if (couponUsed || isNaraCampaignOrder) {
        console.debug('[교환/반품] 결제시 쿠폰을 사용했을 경우 반품이 불가능합니다.');
      }

      setReturnable(!couponUsed && !isNaraCampaignOrder);
    }
  }, [orderNo, order.loaded, order.coupons, order.orderCampaigns]);

  // 주문 및 환불정보 조회
  const fetchRefundInfo = async () => {
    // console.log('####### orderNo', orderNo);
    if (!order.loaded) {
      try {
        console.log('-----------------------------------> 주문정보 조회 시작', orderNo);
        // setUsedCoupon({ loaded: false, error: false, datas: [] });

        // firestore 주문(order) document reference
        const orderDocRef = doc(getFirestore(), 'order', orderNo);

        // 주문정보 조회
        const orderDocSnap = await getDoc(orderDocRef);
        if (!scriptedRef.current) return;

        if (!orderDocSnap.exists()) throw new Error('Order not exists');

        // 조회된 주문정보
        const orderData = orderDocSnap.data();

        // 주문자 정보가 일치하지 않음
        if (orderData.user.uid !== user._id) throw new Error('Unauthorized order.');

        // 주문상품정보 조회
        const orderProductDocsSnap = await getDocs(collection(orderDocRef, 'order_products'));

        if (!scriptedRef.current) return;

        const orderItems = orderProductDocsSnap.docs.map((orderItemDoc) => orderItemDoc.data());

        // 주문에 적용된 캠페인 정보
        const orderCampaignDocsSnap = await getDocs(collection(orderDocRef, 'order_campaigns'));
        if (!scriptedRef.current) return;

        const orderCampaigns = orderCampaignDocsSnap.empty
          ? []
          : orderCampaignDocsSnap.docs.map((orderCampaignDoc) => orderCampaignDoc.data());
        if (orderCampaigns.length > 0) {
          console.log('주문에 적용된 캠페인이 있습니다.');
        }

        // 주문에 사용된 쿠폰
        const coupons = [];
        if (orderDocSnap.data().coupon.length > 0) {
          // 사용자의 보유쿠폰 목록 조회
          const usedCouponDocsSnap = await getDocs(
            query(collection(getFirestore(), `member/${user._id}/coupon_download`), where(documentId(), 'in', orderDocSnap.data().coupon))
          );

          if (!scriptedRef.current) return;

          usedCouponDocsSnap.docs.forEach((couponDownloadDoc) => {
            coupons.push(couponDownloadDoc.data());
          });
        }

        // setUsedCoupon({ loaded: true, error: false, datas: coupons });
        console.log('#orderData: ', orderData);

        setOrder({ loaded: true, ...orderData, orderItems, orderCampaigns, coupons });

        // setOrder({ loaded: true, error: true });
        // setUsedCoupon({ loaded: true, error: true, datas: [] });
        console.log('-----------------------------------> 주문정보 조회 완료', orderNo);
      } catch (error) {
        console.error('교환/환불 처리를 위한 정보 조회 중 오류.', error);
        if (scriptedRef.current) {
          setOrder({ loaded: true, error: true });
          // setUsedCoupon({ loaded: true, error: true, datas: [] });
        }
      }
    } else {
      console.warn('이미 주문정보 로드됨.'); // todo
    }
  };

  React.useLayoutEffect(() => {
    fetchRefundInfo(orderNo);
  }, [orderNo]);

  React.useEffect(() => {
    if (order.loaded && order.error) {
      WoAlert.fire('', `주문정보 조회 중 오류가 발생했습니다.<br />[주문번호=${orderNo}]`, 'warning').then(() => {
        navigate(-1);
      });
    }
  }, [order?.loaded, order?.error]);

  React.useEffect(() => {
    // if (order.loaded) {
    //   console.debug('[교환/반품] 조회된 주문정보: ', order);
    // } else {
    //   console.log('####################################### fetchMyOrder: ');
    //   console.debug(`[교환/반품] 주문정보 조회 [orderNo=${orderNo}]`);
    //
    //   // 주문정보 조회
    //   getDoc(doc(getFirestore(), 'order', orderNo))
    //     .then((orderSnapshot) => {
    //       // 존재하지 않는 주문정보
    //       if (!orderSnapshot.exists()) {
    //         console.error(`[1kmwine] 존재하지 않는 주문정보를 조회함. [oid=${orderNo}]`);
    //         WoAlert.fire(`존재하지 않는 주문입니다.`, '', 'warning').then(() => {
    //           navigate(-1);
    //           // navigate(`/my/order/${orderNo}/detail`, { replace: true });
    //         });
    //         return false;
    //       }
    //
    //       if (scriptedRef.current) {
    //         console.log('------------------------------>');
    //         // firestore order -> order_products(subcollection)
    //         getDocs(collection(orderSnapshot.ref, 'order_products')).then((orderProductsSnapshot) => {
    //           const orderItems = [];
    //           if (scriptedRef.current) {
    //             orderProductsSnapshot.forEach((orderItemDoc) => {
    //               orderItems.push(orderItemDoc.data());
    //             });
    //
    //             const order = { ...orderSnapshot.data(), orderItems };
    //             console.debug('[교환/반품] 조회된 주문정보: ', order);
    //             setOrder({ loaded: true, ...order });
    //           }
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('[교환/반품] 주문정보 조회요류 ', error);
    //       // 교환/반품 신청 화면에서 주문정보 조회 실패
    //       try {
    //         const auth = getAuth();
    //         httpsCallable(
    //           getFirebaseFunctions(),
    //           'call-cdm-clo-error'
    //         )({
    //           code: CLO_CODE.ORDER_LOAD_ERROR,
    //           title: `교환/반품 신청 화면에서 주문조회 실패 [uid=${auth.currentUser.uid}, oid=${orderNo}]`,
    //           msg: `${JSON.stringify(error)}`,
    //           which: `${location.pathname}${location.search}`,
    //           param: { uid: auth?.currentUser ? auth.currentUser.uid : 'Anonymous', orderNo }
    //         })
    //           .then(console.log)
    //           .catch(console.error);
    //       } catch (e) {
    //         /* DO NOTHING */
    //       }
    //       WoAlert.fire('', `주문정보 조회 중 오류가 발생했습니다.<br />[주문번호=${orderNo}]`, 'warning').then(() => {
    //         navigate(-1);
    //       });
    //       return false;
    //     });
    // }
  }, [order]);

  // 선택한 상품 목록
  const [checkedList, setCheckedList] = React.useState([]);

  // 상품선택 토글처리
  const toggleOrderProduct = (orderItem) => {
    const list = [...checkedList];
    if (checkedList.indexOf(orderItem.id) >= 0) {
      list.splice(checkedList.indexOf(orderItem.id), 1);
    } else {
      list.push(orderItem.id);
    }
    setCheckedList(list);
  };

  /* 교환/반품 대상상품 목록[start] */
  const orderProductList = React.useMemo(() => {
    return (
      order.loaded && (
        <Box>
          {order.orderItems?.map((orderItem) => {
            const checked = checkedList.indexOf(orderItem.id) >= 0;
            // console.log('------- orderItem: ', orderItem);
            return (
              <OrderCard
                key={`order-excrtn-${orderItem.product_id}`}
                disabled={requesting}
                orderProduct={orderItem}
                onClick={(e, orderItem) => toggleOrderProduct(orderItem)}
                checked={checked}
              />
            );
          })}
        </Box>
      )
    );
  }, [order, checkedList, requesting]);
  /* 주문취소 대상상품 목록[end] */

  /** 교환/반품 요청 */
  const requestOrderExcRtn = React.useCallback((excrtnType, orderNo, orderProductIds, reason) => {
    console.groupCollapsed('[교환/반품] 요청 데이터');
    console.log('- excrtnType: ', excrtnType);
    console.log('- orderNo: ', orderNo);
    console.log('- orderProductIds: ', orderProductIds);
    console.log('- reason: ', reason);
    console.groupEnd();

    const word = excrtnType === 'exchange' ? '교환' : '반품';
    console.debug(`[교환/반품] ${word} 요청을 시작합니다. `, orderNo, orderProductIds, reason);
    setRequesting(true);

    return requestExcRtn(excrtnType, orderNo, orderProductIds, reason).then((response) => {
      console.debug(`[교환/반품] ${excrtnType}요청 결과: `, response);
      const { result, data } = response.data;

      // 요청 실패
      if (result.code !== 0) {
        throw new Error(response);
      } else {
        console.info(`[교환/반품] ${excrtnType} 요청 성공: `);
        globalDispatch(
          openSnackbar({
            open: true,
            message: `선택한 상품이 ${word}요청 되었습니다.`,
            variant: 'alert',
            alert: { color: 'success' },
            close: true,
            autoHideDuration: 4000
          })
        );

        if (scriptedRef.current) {
          navigate(`/my/order/${orderNo}/detail`, { replace: true });
        }
      }
      return data;
    });
  }, []);

  const handleOrderExcRtnClick = React.useCallback(() => {
    const word = excrtnType === 'exchange' ? '교환' : '반품';
    console.debug(`[교환/반품] ${word} 요청 버튼 클릭: `, checkedList, reason);

    WoAlert.fire({
      title: (
        <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
          {word} 신청
        </Typography>
      ),
      html: `<div style='line-height: 1.5rem; text-align: left'>선택한 상품을 ${word}하시겠습니까?</div>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: `${word}신청`,
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        requestOrderExcRtn(excrtnType, orderNo, checkedList, `[${reasonType}] ${reason}`).catch((error) => {
          console.error(`[교환/반품] ${word}요청 실패`, error);
          WoAlert.fire(`${word} 요청 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error');
          setRequesting(false);
          return false;
        });
      }
    });
  });

  // 환불 상품금액 계산
  const refundProductPrice = React.useMemo(() => {
    // 타입이 반품(refund)이 아님
    if (excrtnType !== 'refund') {
      console.debug(`[교환/반품] 교환[exchange]의 경우 환불 상품금액을 계산하지 않습니다`);
      return 0;
    }

    // 선택한 상품이 없음
    if (checkedList.length === 0) {
      console.debug(`[교환/반품] 환불 상품금액을 계산할 선택상품이 없습니다`);
      return 0;
    }

    // 환불 상품금액 합산
    let refundTotal = 0;

    // 체크된 상품들의 금액만 추리기
    order.orderItems.forEach((orderProduct) => {
      const { price, quantity, id } = orderProduct;
      if (checkedList.indexOf(id) >= 0) {
        refundTotal += price.original * quantity;
      }
    });
    console.debug(`[교환/반품] 환불 상품금액: `, refundTotal);
    return refundTotal.toLocaleString();
  }, [excrtnType, checkedList]);

  // 환불 금액 계산요청 취소토큰
  const cancelTokenSource = React.useRef(null);

  // 환불 서비스 수수료 - todo delete
  const [refundCommission, setRefundCommission] = React.useState({ loading: false, refund: null, error: false });

  React.useEffect(() => {
    if (cancelTokenSource.current) {
      try {
        console.log('[교환/반품] 반품 조건이 변경되어 기존 요청을 취소합니다.');
        cancelTokenSource.current.cancel('[교환/반품] 반품 조건이 변경되어 기존 요청을 취소합니다.');
        setRefundCommission({ loading: false, refund: null, error: false });
      } catch (e) {
        console.error('[교환/반품] Cancel calculate refund commission.', e);
      }
    } else {
      // console.info('[교환/반품] 요청 취소할 기존 요청이 없습니다');
    }

    // console.log('#----> excrtnType: ', excrtnType);

    // 타입이 반품(refund)이 아님
    if (excrtnType !== 'refund') {
      console.debug(`[교환/반품] 교환[exchange]의 경우 환불 서비스 수수료를 계산하지 않습니다`);
      setRefundCommission({ loading: false, refund: null, error: false });
      return false;
    }

    // 선택한 상품이 없음
    if (checkedList.length === 0) {
      console.debug(`[교환/반품] 환불 서비스 수수료를 계산할 선택상품이 없습니다`);
      setRefundCommission({ loading: false, refund: null, error: false });
      return false;
    }

    setRefundCommission({ loading: true, refund: null });
    // console.log('주문번호 : ', orderNo);
    cancelTokenSource.current = axios.CancelToken.source();
    // console.log('반품정보계산 요청취소 토큰: ', cancelTokenSource.current);

    // 환불 서비스 수수료 계산
    calculateRefundCommission(orderNo, checkedList, cancelTokenSource.current.token)
      .then((response) => {
        const { result, data } = response.data;
        // 계산요청 성공
        if (result.code === 0) {
          console.debug('[교환/반품] 환불 금액정보: ', data);
          setRefundCommission({ loading: false, refund: data.refund, error: false });
        } else {
          setRefundCommission({ loading: false, refund: null, error: true });
        }
      })
      .catch((error) => {
        console.error('[교환/반품] 환불 서비스 수수료금액 계산요청 실패.', error);
        setRefundCommission({ loading: false, refund: null, error: true });
      });
  }, [excrtnType, checkedList]);

  // 요청버튼 텍스트
  const requestButtonLabel = React.useMemo(() => {
    if (excrtnType === null) return '방법을 선택해주세요';
    if (checkedList.length === 0) return '상품을 선택해주세요';
    if (reasonType === null) return '사유를 선택해주세요';
    if (refundCommission.loading) return '환불금액 계산중...';

    const label = `${excrtnType === 'exchange' ? '교환' : '반품'}${requesting ? '요청 중...' : ' 신청'}`;
    console.debug(`[교환/반품] 버튼 라벨: ${label}`);
    return label;
  }, [excrtnType, checkedList, reasonType, requesting, refundCommission]);

  // 요청 가능
  const requestAble = React.useMemo(
    () => excrtnType == null || reasonType === null || checkedList.length === 0 || refundCommission.loading,
    [excrtnType, checkedList, reasonType, refundCommission]
  );

  const helmet = useMemo(() => <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 교환/반품 신청`} />, []);

  // render
  return (
    <>
      <Backdrop open={!order?.loaded} sx={{ zIndex: 2400 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <WineBottleLoadingLottie />
          <Typography variant="caption" color="#fff">
            주문정보를 불러오고 있습니다.
          </Typography>
        </Box>
      </Backdrop>

      <BackHeader title="교환/반품">
        {helmet}
        <Container maxWidth="sm">
          {/* 교환/반품 선택 */}
          {order.loaded && !order.error ? (
            <>
              <Box pt="30px">
                <FormControl>
                  <FormLabel id="order-excrtn-buttons-group" sx={{ pb: '7px' }}>
                    <Typography variant="subtitle1" fontSize="14px" fontWeight={800} lineHeight="normal" color="text.primary">
                      처리 방법 선택
                    </Typography>
                  </FormLabel>
                  <RadioGroup aria-labelledby="order-excrtn-buttons-group" name="type" value={excrtnType} onChange={handleExcRtnTypeChange}>
                    <FormControlLabel
                      value="exchange"
                      disabled={requesting}
                      control={<Radio />}
                      label={<Typography variant="body1">교환</Typography>}
                    />
                    <FormControlLabel value="refund" control={<Radio />} disabled={!returnable || requesting} label={returnCheckLabel} />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Divider sx={{ my: '30px' }} />
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" mt="12px" minHeight="calc(var(--vh, 1vh) * 100)" pt="16px">
              <CircularProgress size={30} color="brand" />
            </Box>
          )}

          {/* 교환/반품 대상상품 목록 */}
          {orderProductList}

          {!returnable && <UseCouponReturnAlert />}

          {order.loaded && !order.error && (
            <>
              <Divider sx={{ my: '30px' }} />
              <Box>
                <FormControl>
                  <FormLabel id="order-excrtn-reason-buttons-group" sx={{ pb: '7px' }}>
                    <Typography variant="subtitle1" fontSize="14px" fontWeight={800} lineHeight="normal" color="text.primary">
                      사유 선택
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="order-excrtn-reason-buttons-group"
                    name="reasonType"
                    value={reasonType}
                    aria-disabled={requesting}
                    onChange={handleReasonType}
                  >
                    <FormControlLabel disabled={requesting} value="상품 파손" control={<Radio />} label="상품 파손" />
                    <FormControlLabel disabled={requesting} value="단순 변심 및 기타" control={<Radio />} label="단순 변심 및 기타" />
                  </RadioGroup>
                </FormControl>
                <Box mt="6px">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    multiline
                    rows={3}
                    inputProps={{ maxRows: 5 }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="상세사유를 입력해주세요."
                  />
                </Box>
              </Box>
            </>
          )}
        </Container>

        {/* 환불 예상금액[start] */}
        {order.loaded && !order.error && excrtnType === 'refund' && (
          <Box bgcolor="background.contrast1" mt={4} pt={1.5} pb="1px">
            <Container maxWidth="sm" component="section">
              <Box>
                <Typography variant="subtitle1" fontSize="14px" fontWeight={800} lineHeight="normal" sx={{ my: '18px' }}>
                  환불 예상 금액
                </Typography>
                {checkedList?.length > 0 ? (
                  <Stack spacing={1.5}>
                    {/* 상품 금액 */}
                    <SpaceBetweenBox>
                      <Typography variant="body1" fontSize="14px">
                        환불 상품 금액
                      </Typography>
                      <Typography variant="body1" fontSize="14px" fontWeight={800}>
                        {order.loaded ? `${refundProductPrice}원` : <Skeleton width={80} />}
                      </Typography>
                    </SpaceBetweenBox>

                    {/* 서비스 수수료 */}
                    <SpaceBetweenBox>
                      <Typography variant="body1" fontSize="14px">
                        환불 서비스 수수료
                      </Typography>
                      <Typography variant="body1" fontSize="14px" fontWeight={800}>
                        {order.loaded && !refundCommission.loading && typeof refundCommission.refund?.commission === 'number' ? (
                          `${refundCommission.refund.commission.toLocaleString()}원`
                        ) : (
                          <Skeleton width={80} />
                        )}
                      </Typography>
                    </SpaceBetweenBox>

                    {/* 할인 금액 */}
                    {order.loaded && order.pay.discount > 0 && (
                      <SpaceBetweenBox>
                        <Typography variant="body1" fontSize="14px">
                          총 할인 금액
                        </Typography>
                        <Typography variant="body1" color="error" fontSize="14px" fontWeight={800}>
                          +{order.pay.discount.toLocaleString()}원
                        </Typography>
                      </SpaceBetweenBox>
                    )}

                    {/* 결제시 사용한 쿠폰 목록 */}
                    {order.loaded &&
                      !order.error &&
                      order.coupons?.map((data, i) => (
                        <SpaceBetweenBox key={`coupon-discount-${i}`} sx={{ opacity: 0.4 }}>
                          <Typography variant="body1" fontSize="14px">
                            {order.coupons.length === i ? <span>&#x251C;</span> : <span>&#x2514;</span>} {data.coupon.name}
                          </Typography>
                          <Typography variant="body2" fontSize="14px" fontWeight={800}>
                            -{(data.coupon.amount >> 0).toLocaleString()}원
                          </Typography>
                        </SpaceBetweenBox>
                      ))}
                  </Stack>
                ) : (
                  <Alert severity="warning" sx={{ mb: '20px' }}>
                    반품할 상품을 선택해주세요.
                  </Alert>
                )}

                <Divider sx={{ mt: '30px' }} />

                <SpaceBetweenBox sx={{ my: '25px' }}>
                  <Typography variant="subtitle1" fontSize="14px" fontWeight={800} lineHeight="normal">
                    최종 환불 금액
                  </Typography>

                  {order.loaded ? (
                    <Box display="inline-flex" alignItems="center">
                      <Typography
                        variant="body1"
                        component="span"
                        fontSize="22px"
                        fontWeight={800}
                        lineHeight="normal"
                        fontFamily="D-DIN-Bold,D-DIN"
                      >
                        {order.loaded && !refundCommission.loading && refundCommission.refund?.total ? (
                          `${refundCommission.refund.total.toLocaleString()}`
                        ) : (
                          <Skeleton width={80} />
                        )}
                      </Typography>
                      <Typography variant="body1" component="span" fontSize="16px" fontWeight={300} sx={{ ml: '2px' }}>
                        원
                      </Typography>
                    </Box>
                  ) : (
                    <Skeleton width={140} />
                  )}
                </SpaceBetweenBox>
              </Box>
            </Container>
          </Box>
        )}

        {/* 환불 예상금액[end] */}

        {order.loaded && (
          <Container maxWidth="xs" sx={{ py: '16px' }}>
            {/* hkcho: startIcon 속성 때문에 개발자 도구에서 에러가 표현되는데 이것은 MUI버그입니다. [issue=https://github.com/mui/material-ui/issues/31235] */}
            <LoadingButton
              fullWidth
              aria-label={requestButtonLabel}
              color="brand"
              loading={requesting}
              disabled={requestAble}
              // loadingPosition="start"
              variant="contained"
              size="x-large"
              onClick={handleOrderExcRtnClick}
            >
              {requestButtonLabel}
            </LoadingButton>
          </Container>
        )}
      </BackHeader>
    </>
  );
}

export default OrderExcRtn;

/** 쿠폰 사용건에 대한 부분 반품 안내 Alert */
const UseCouponReturnAlert = React.memo(() => (
  <Alert icon={false} color="error" sx={{ mt: '20px' }}>
    <AlertTitle sx={{ display: 'flex', alignItems: 'center', color: 'text.primary', fontSize: 11, fontWeight: 900, mb: '11px' }}>
      <InfoIcon sx={{ color: '#E37462', width: '15px', height: '18px', marginRight: '4px' }} />
      부분 반품 안내
    </AlertTitle>
    <Typography variant="body2" fontSize={11} color="text.primary">
      쿠폰을 사용하여 결제하신 경우, 부분 반품이 불가합니다.
    </Typography>
  </Alert>
));
