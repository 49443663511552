import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// project imports
import { KmPromotionState, ORDER } from 'config';

// material-ui
import { Box, Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

function OrderActionButtons({ order, wrapper: WrapperComponent = Box, buttonProps = {}, handleClick }) {
  const buttonSet = useMemo(() => generateButtonSet(order, handleClick, buttonProps), [order]);

  return buttonSet !== null ? (
    <WrapperComponent>
      <ButtonGroup fullWidth disableElevation variant="outlined" size="large" color="brand" aria-label="주문기능 버튼모음" fontSize={16}>
        {buttonSet}
      </ButtonGroup>
    </WrapperComponent>
  ) : null;
}

OrderActionButtons.propTypes = {
  order: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  wrapper: PropTypes.any,
  buttonProps: PropTypes.object
};

export default OrderActionButtons;

function generateButtonSet(order, handleClick, buttonProps) {
  const buttons = [];

  // '주문취소' 버튼
  const orderCancelButton = (
    <OrderActionButton {...buttonProps} onClick={(e) => handleClick(e, 'order.cancel')}>
      주문취소
    </OrderActionButton>
  );

  // '공동 구매 취소' 버튼
  const orderCobuyCancelButton = (
    <OrderActionButton {...buttonProps} onClick={(e) => handleClick(e, 'order.coBuy.cancel')}>
      공동구매 취소
    </OrderActionButton>
  );

  const orderPromotionCancelButton = (
    <OrderActionButton {...buttonProps} onClick={(e) => handleClick(e, 'order.cancel')}>
      예약 주문 취소
    </OrderActionButton>
  );

  // '픽업 일시 변경' 버튼
  const updatePickupDateButton = (
    <OrderActionButton {...buttonProps} onClick={(e) => handleClick(e, 'order.updatePickDate')}>
      픽업일시 변경
    </OrderActionButton>
  );

  switch (order.state) {
    case ORDER.STATE.REQUEST: // 주문 확인 중
    case ORDER.STATE.APPROVED: // 승인된 주문
      if (order.co_buy?._id) {
        // 공동구매 진행 중에는 주문취소 가능
        if (order.cobuy_state === 'WAIT') {
          buttons.push(orderCobuyCancelButton); // 공동 구매 취소
        }
      }

      // 예약 주문 중이면서 픽업일시 변경 가능한 경우
      if (order?.promotion?.pickup.state === 'none' && new Date(order?.promotion?.period.end_dt) > new Date()) {
        buttons.push(orderPromotionCancelButton);
      }

      // 공동구매가 아닌 상품
      if (order.cobuy_state === 'NONE' && order?.promotion?.pickup.state !== 'none') {
        // todo keymedi2408: 키메디 공동구매 종료 후 삭제할 것.
        if (order.from === 'keymedi2408') {
          // 2024-09-01 이후로 주문취소 불가
          if (new Date() < new Date('2024-09-01')) {
            buttons.push(orderCancelButton); // 주문 취소
          }
        } else {
          buttons.push(orderCancelButton); // 주문 취소
        }

        // 티켓 상품일 경우 픽업일시 변경불가
        if (order.order_products[Object.keys(order.order_products)[0]].items[0]?.pdata.category !== 'ticket') {
          // todo keymedi2408: 키메디 공동구매 종료 후 삭제할 것.
          console.log('#--> ', order.from);
          // order.from === 'keymedi2408'일 경우 픽업일시 변경 안됨
          if (order.from !== 'keymedi2408') {
            buttons.push(updatePickupDateButton); // 픽업일시 변경
          }
        }
      }

      break;

    case ORDER.STATE.CANCEL: // 주문취소(사용자)
    case ORDER.STATE.PICK_UP_DONE: // 픽업완료
    case ORDER.STATE.REJECT: // 주문취소(판매사)
      /* DO NOTHING */
      break;

    default:
      console.warn('[1kmwine] 주문기능 처리가 필요한 주문', order.state);
  }
  return buttons.length > 0 ? (
    <>
      {buttons.map((btn, i) => (
        <React.Fragment key={`order-${order.oid}-action-${i}`}>{btn}</React.Fragment>
      ))}
    </>
  ) : null;
}

const OrderActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '5px',
  borderColor: '#e7e4eb',
  color: theme.palette.text.primary,
  fontSize: 14,
  lineHeight: 1,
  paddingTop: '12px',
  paddingBottom: '12px'
}));
