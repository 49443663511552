import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { useLocationState } from 'react-router-use-location-state';
import { Helmet } from 'react-helmet-async';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getApp } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { WoAlert } from 'utils/kmwine-alerts';

// project imports
import { dispatch, useSelector } from 'store';
import { fetchCodes } from 'store/slices/wo-constants';
import { CLO_CODE, USER_LOCATION_TYPE } from 'config';
import { useAmplitude, useAuth, useScriptRef, useWineOne } from 'hooks';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { getOrderableList } from 'services/PdataService';
import { MainProductCard, SoldOutChip, VendorItem } from './components';
import { BackButton, CartButton, ReviewWriteBtn, SpaceBetweenBox } from 'components';
import { ToolbarTitle } from 'components/toolbar';
import KeywordAlarmAlert from 'components/widgets/KeywordAlarmAlert';
import { PdataInfo } from 'components/pdata';

// material-ui
import {
  AppBar,
  Box,
  ButtonBase,
  Card,
  CircularProgress,
  Container,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  SvgIcon,
  Toolbar,
  Typography
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

// assets
import QuoteLeft from 'assets/images/one_line_review_left _double quotation mark.svg';
import QuoteRight from 'assets/images/one_line_review_right_double quotation mark.svg';

// 주문 가능 와인샵 페이지네이트 기본정보
const initialPage = Object.freeze({
  current: 1,
  total_pages: 0,
  total_results: 0,
  size: 10, // 한번에 호출할 상품 개수
  done: false
});

const initialSearchState = Object.freeze({
  initialized: false,
  searched: false,
  searching: false,
  error: false
});

/**
 * 와인 상세
 *  - 주문 가능 매장 목록표현
 *
 * @returns {JSX.Element}
 * @authors 조현권<hkcho@wineone.io>, 이재일<leeji@wineone.io>
 * @history
 *   - 2023. 02. 01 : 입점샵 표현 개선
 *   - v1.0.9 :
 *     - 핀내, 핀외 상품 판매정보 표현
 *     - 입점샵 정보 상세표현
 */
function WineDetail() {
  const scriptedRef = useScriptRef();

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { onNativeBackPressed } = useWineOne();

  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  const { logEvent } = useAmplitude();
  const { user } = useAuth(); // 로그인 사용자 정보

  // 사용자 위치정보, 서비스 상수
  const { userLocation, woConstants } = useSelector((state) => state);

  React.useLayoutEffect(() => {
    dispatch(fetchCodes(['service_tag']));
  }, [woConstants]);

  // pdata 아이디
  const { pdata_id } = useParams(); // Firestore(pdata._id)
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  // 용량
  const capacity = React.useMemo(() => params.get('capacity'), [params]);
  // 상품 원본데이터
  const [pdata, setPdata] = React.useState(location.state?.pdata ?? { loaded: false });

  // pdata상세 열릴 때 기본 탭
  const [pdataInfoReviewTab, setPdataInfoReviewTab] = React.useState(false);

  // 와인 상세정보 보기
  const moveToWineInfo = (initTab) => {
    // todo 임시방편
    // todo case: pdataInfo에서 리뷰 작성 후 리뷰목록 클릭 시 modal이 안뜸
    // if (showPdataInfo) return false;

    if (!pdata.loaded) {
      console.warn('아직 상품정보가 로드되지 않았습니다.');
      return false;
    }

    setPdataInfoReviewTab(initTab === 'review');
    setShowPdataInfo(true, { method: 'push' });
  };

  // 매장주문 프리뷰 화면으로 이동
  const moveToOrderPreview = (vendorItem) => {
    console.debug(`[WineDetail] 매장주문 프리뷰 화면으로 이동. [vendor.id=${vendorItem.vendor.id}]`);

    const { vendor } = vendorItem;
    const vendorId = vendor._id ?? vendor.id;

    // Amplitude Select Store
    logEvent.selectStore('주문 가능 와인샵', vendorId, vendor.biz?.name);
    navigate(`/vendor/d/${vendorId}`, { state: { product: vendorItem, vendor: { ...vendor } } });
  };

  // ------------------------------------------------------------------------ 주문 가능 와인샵 [START]
  // 주문 가능 와인샵 목록 페이징 정보
  const [page, setPage] = React.useState(initialPage);
  const [searchState, setSearchState] = React.useState(initialSearchState);

  // 주문 가능 와인샵 목록
  const [vendorList, setVendorList] = React.useState([]);
  // 주문 가능 와인샵 목록 개수1
  const [totalCount, setTotalCount] = React.useState(null);
  // 상품 최저가 가격
  const [priceMin, setPriceMin] = React.useState(location.state?.priceMin ?? null);
  // [다른 지역 주문 가능] 여부
  const [hasProduct, setHasProduct] = React.useState(location.state?.hasProduct ?? { inPin: true, outOfPin: false });
  // 셀프 프로모션 진행상품 여부
  const [onPromo, setOnPromo] = React.useState(location.state?.onPromo ?? false);

  React.useLayoutEffect(() => {
    fetchVendorList(1);
  }, [pdata_id]);

  const updateSearchState = (pageState, searchState) => {
    // 페이지번호 갱신
    setPage(pageState);
    // 검색상태
    setSearchState(searchState);
  };

  // 주문 가능 와인샵 목록
  const fetchVendorList = async (current) => {
    console.debug('[1KMWINE] 주문 가능 와인샵 목록 조회 시작.');

    if (current !== 1 && searchState.searching) {
      console.warn('[1KMWINE] 주문 가능 와인샵 목록을 요청 중 입니다.');
      return false;
    }

    // 1page 조회일 경우
    if (current === 1) {
      // 기존 검색값 모두 초기화
      setPage(initialPage);
      setSearchState(initialSearchState);
    } else {
      // 검색상태로 변경
      setSearchState({ ...searchState, searching: true, error: false });
    }

    // 주문 가능 와인샵 목록 필터
    const body = {
      page: {
        current,
        size: page.size
      },
      pdata_id,
      coordinate: userLocation.coord,
      filter: { pin: 3 }
    };

    // 용량(capacity) 필터링
    if (!capacity || capacity === '' || capacity.length === 0) {
      body.filter.capacity = [];
    } else {
      body.filter.capacity = [capacity];
    }

    const result = await getOrderableList(body).catch((error) => {
      if (error.message !== 'Network Error' && !error.message.startsWith('timeout of')) {
        sendCloError({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `주문 가능 와인샵 목록 조회 중 오류 (${error.name}) `,
          msg: `[uid=${user._id}][pdata_id=${pdata_id}] ${error.message ?? 'Unexpected'}`,
          which: `${location.pathname}${location.search}`,
          param: { uid: user._id, pdata_id, error }
        })
          .then(console.log)
          .catch(console.error);
      }
      return { error };
    });

    if (!scriptedRef.current) {
      console.warn('[WineDetail][fetchVendorList] Unmounted component.');
      return;
    }

    // 주문 가능 와인샵 목록 조회 중 오류 발생
    if (result.error) {
      console.error('[WineDetail][fetchVendorList] 주문 가능 와인샵 목록 조회 중 오류 발생.', result.error);
      await WoAlert.fire(`와인샵 조회 중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.`, '', 'error').then(() => {
        navigate(-1);
      });

      return undefined;
    }

    const { success, list, page: resultPage, info } = result.data;

    // response값이 실패로 반환됨
    if (!success) {
      console.error('[1KMWINE] 주문 가능 와인샵 목록 조회 중 오류 발생.');
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: '주문 가능 와인샵 목록 조회 중 오류 발생',
        msg: `[uid=${user._id}] API Response값이 잘못 반환됨`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user._id,
          error: success
        }
      })
        .then(console.log)
        .catch(console.error);
      return undefined;
    }

    const done = resultPage.current * resultPage.size >= resultPage.total_results;

    updateSearchState(
      { ...resultPage, current, done },
      {
        initialized: true,
        searched: true,
        searching: false
      }
    );

    const orderVendorList = current === 1 ? list : [...vendorList, ...list];

    setHasProduct({
      inPin: (info.distance_minimum ?? 0) <= 1000,
      outOfPin: (info.distance_minimum ?? 0) > 1000
    });
    setPriceMin(info.price_min ?? 0);
    setOnPromo((info.promo_cnt ?? 0) > 0);

    setTotalCount(resultPage.total_results);
    setVendorList(orderVendorList);
  };
  // ------------------------------------------------------------------------ 주문 가능 와인샵 [END]

  // 주문가능매장목록
  const vendorListComponent = React.useMemo(() => {
    if (!scriptedRef.current) return null;
    if (searchState.searched && totalCount === 0) {
      return (
        <Card elevation={0} sx={{ textAlign: 'center', p: 3, bgcolor: 'transparent' }}>
          주문 가능한 샵이 없습니다.
        </Card>
      );
    }

    return (
      <TransitionGroup>
        {searchState.searched &&
          totalCount > 0 &&
          vendorList.map((vendor, i) => {
            // 남은 재고
            const { quantity } = vendor;
            const product = vendor;
            const soldOut = quantity <= 0; // 품절여부

            let promFlag = false; // 프로모션 여부
            if (vendor.promotion) {
              const { promotion } = vendor;
              const promStartDt = moment(promotion.start_dt, 'YYYY-MM-DD HH:mm:ss');
              const promEndDt = moment(promotion.end_dt, 'YYYY-MM-DD HH:mm:ss');
              const now = moment().format('YYYY-MM-DD HH:mm:ss');

              promFlag = moment(now).isBetween(promStartDt, promEndDt);
            }
            return (
              <Fade key={`prod-grow-${i}`} style={{ transitionDelay: `200ms` }}>
                <ListItem
                  disablePadding
                  sx={{
                    mb: '12px',
                    borderRadius: '14px',
                    bgcolor: '#ffffff'
                  }}
                >
                  {/* 품절 표현 */}
                  {soldOut && <SoldOutChip />}

                  <ListItemButton
                    disabled={soldOut}
                    alignItems="flex-start"
                    sx={{ width: 1, py: '10px', px: '15px' }}
                    onClick={() => {
                      moveToOrderPreview(product);
                    }}
                  >
                    <VendorItem product={product} promFlag={promFlag} />
                  </ListItemButton>
                </ListItem>
              </Fade>
            );
          })}
      </TransitionGroup>
    );
  }, [vendorList]);

  React.useEffect(() => {
    // 주문가능 매장이 없고, pdata가 로드되었을 때
    if (searchState.searched && totalCount === 0 && pdata.loaded) {
      askKeywordAlarm();
    }

    return () => {
      if (scriptedRef.current) {
        setShowKeywordAlert(false);
      }
    };
  }, [searchState.searched, totalCount, pdata.loaded]);

  /**
   * 키워드 알림등록 묻기
   *  - 해당 키워드가 알림에 등록되어 있는지 확인.
   *  - 키워드 알림이 등록되어있지 않을 경우 등록여부를 사용자에게 질문.
   */
  const askKeywordAlarm = async () => {
    const wineName = pdata.name.ko;
    console.debug(`주문가능한 상점이 없는 상품 대한 키워드 알림 등록여부 확인. 상품명=${wineName}`);

    const auth = getAuth();

    const snapshot = await getDocs(
      query(collection(getFirestore(getApp()), `keyword`), where('user.id', '==', auth.currentUser.uid), where('text', '==', wineName))
    ).catch((error) => error);

    if (!scriptedRef.current) {
      console.warn('[WineDetail][askKeywordAlarm] Unmounted component.');
      return;
    }

    console.debug('키워드 등록여부 확인: ', snapshot);
    if (!snapshot.error && snapshot.empty) {
      // 검색어 알림 등록용 화면 띄우기
      setShowKeywordAlert(true);
    } else {
      console.debug('이미 등록된 알림키워드 입니다. ', wineName);
    }
  };

  // 키워드 알림등록 alert 보이기
  const [showKeywordAlert, setShowKeywordAlert] = React.useState(false);

  /** 키워드 알림등록 alert 닫기 */
  const handleShowKeywordAlert = () => {
    setShowKeywordAlert(false);
  };

  // pdata 상세정보 표현여부
  const [showPdataInfo, setShowPdataInfo] = useLocationState('wd_showPdataInfo', false);

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`검색 화면에서 '뒤로가기' 버튼이 감지되었습니다.`);
    // 상품검색 영역이 열려있었을 경우
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  }, [location]);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // 스크롤 디렉션
  const [y, setY] = React.useState(window.scrollY);

  const handleScrollEvent = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      // 스크롤이 아래방향으로 ( trigger 쓰지말고 y로 판단 )
      if (y < window.scrollY) {
        // 스크롤이 바닥에 거의 닿았을 때
        if (window.scrollY + window.innerHeight >= document.body.offsetHeight - 60) {
          if (!searchState.searched) return; // 최초 조회시 중복요청 제외
          if (searchState.searching) return; // 판매상품(product) 페이지 요청 중일 경우 pass
          // 다음 페이지 호출
          if (!page.done) {
            console.log(`[1KMWINE] 다음 페이지 호출 (${page.current + 1})`);
            fetchVendorList(page.current + 1);
          }
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  // 스크롤 감시
  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  // [임시] pdata.category가 'ticket'인 pdata 대응
  // const isWine = React.useMemo(() => pdata?.category === WINE.value, [pdata?.category]);

  // pdata 리뷰 요약
  const pdataReviewSummary = React.useMemo(() => {
    if (!pdata) return null;

    const { _id, avg_star, writer_count } = pdata;
    if (!_id || avg_star === undefined || writer_count === undefined) return null;

    // 별점과 리뷰 작성자 수가 있을 경우
    if (avg_star > 0 && writer_count > 0) {
      return (
        <ButtonBase onClick={() => moveToWineInfo('review')}>
          <Stack
            direction="row"
            spacing="4px"
            divider={
              <Box display="flex" justifyContent="center" alignItems="center" width="4px" height="20px">
                <Box height="13px" width="1.3px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
              </Box>
            }
          >
            {/* 리뷰 평균 별점 */}
            <Stack direction="row" spacing="4px" alignItems="center">
              <FilledRatingIcon />
              <Typography sx={{ fontFamily: 'D-DIN', fontSize: 16, fontWeight: 700, lineHeight: 'normal', pt: '1px' }}>
                {pdata?.avg_star?.toFixed(1)}
              </Typography>
            </Stack>
            {/* 리뷰 개수 */}
            <Box display="flex" alignItems="center">
              <Typography sx={{ opacity: 0.7, fontFamily: 'D-DIN', fontSize: 14, lineHeight: 'normal' }}>
                리뷰 <b>{pdata.writer_count > 99999 ? '99,999+' : pdata.writer_count.toLocaleString()}</b>개
              </Typography>

              {/* <Typography sx={{ fontFamily: 'D-DIN', fontSize: 14, fontWeight: 700, lineHeight: 'normal', opacity: 0.7 }}>
                리뷰&nbsp;
              </Typography>
              <Typography sx={{ fontFamily: 'D-DIN', opacity: 0.7, fontSize: 16, fontWeight: 700, lineHeight: 'normal' }}>
                {pdata.writer_count > 99999 ? '99999+' : pdata.writer_count.toLocaleString()}
              </Typography>
              <Typography sx={{ fontFamily: 'D-DIN', fontSize: 14, fontWeight: 700, lineHeight: 'normal', opacity: 0.7 }}>개</Typography> */}
              <ReviewWriteBtn color="#70677D" />
            </Box>
          </Stack>
        </ButtonBase>
      );
    }

    // 별점과 리뷰 작성자 수가 없음
    return (
      <ButtonBase onClick={() => navigate(`/my/review/${_id}/write`)}>
        <Stack
          direction="row"
          spacing="6px"
          divider={
            <Box display="flex" alignItems="center" pb="1px">
              <Box height="12px" width="1px" bgcolor={alpha(theme.palette.text.primary, 0.1)} />
            </Box>
          }
        >
          {/* 리뷰 평균 별점 */}
          <Stack direction="row" spacing="4px" alignItems="center">
            <FilledRatingIcon />
            <Typography sx={{ fontFamily: 'D-DIN', fontSize: 16, fontWeight: 700, lineHeight: 'normal', pt: '1px' }}>0.0</Typography>
          </Stack>
          {/* 리뷰 개수 */}
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontSize: 14, fontWeight: 700, lineHeight: 'normal', opacity: 0.7 }}>첫 리뷰를 작성 해보세요!</Typography>
            <ReviewWriteBtn color="#70677D" />
          </Box>
        </Stack>
      </ButtonBase>
    );
  }, [pdata]);

  // 사용자 위치 표시
  const userLocationLabel = React.useMemo(() => {
    const { type, currentPin } = userLocation;
    let label = '설정 중';
    if (type === USER_LOCATION_TYPE.CURRENT) {
      label = '현재 위치';
    } else if (type === USER_LOCATION_TYPE.PIN) {
      label = currentPin ? currentPin.name : '설정 중';
    } else {
      console.debug('위치를 아직 알 수 없습니다.', userLocation);
      if (userLocation.loading) {
        console.info('위치 정보를 가져오고 있습니다.');
      } else {
        console.warn('잘못된 위치 타입. ', type);
      }
    }
    return (
      <Box height={30} display="flex" alignItems="center" mb={1}>
        <PeriscopeFilledIcon />
        <Typography component="span" sx={{ fontSize: 12, fontWeight: 700, lineHeight: 'normal', color: 'rgba(34, 3, 72, 0.7)' }}>
          {label}
          <Typography component="span" sx={{ fontSize: 12, fontWeight: 400, lineHeight: 'normal', color: 'rgba(34, 3, 72, 0.7)' }}>
            &nbsp;핀으로부터 가까운 거리순
          </Typography>
        </Typography>
      </Box>
    );
  }, [userLocation]);

  // render
  return (
    <Box className="product-detail-page" minWidth={320} minHeight="calc(var(--vh, 1vh) * 100)">
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 주문 가능 와인샵`} />
      <AppBar position="sticky" elevation={0}>
        <Container disableGutters maxWidth="xs" sx={{ px: [0, 0, 0, 0] }}>
          <Toolbar>
            <BackButton color="inherit" onClick={pageBackFunc} />
            <ToolbarTitle>주문 가능 와인샵</ToolbarTitle>
            <Box flexGrow={1} />
            {/* 장바구니 버튼 */}
            <CartButton showBadge />
          </Toolbar>

          <MainProductCard
            onClick={moveToWineInfo}
            onError={() => {
              pageBackFunc();
            }}
            pdata={pdata}
            onPdataLoaded={(pdata) => {
              setPdata(() => pdata);
            }}
            pdataId={pdata_id}
            capacity={capacity}
            priceMin={priceMin}
            hasProduct={hasProduct}
            onPromo={onPromo}
            showPdataInfo={showPdataInfo}
          />
        </Container>
      </AppBar>

      <Container maxWidth="xs" sx={{ pt: '20px', pb: '24px' }}>
        <Box height={20}>{pdataReviewSummary}</Box>
        {/* 한줄평[start] */}
        <Box display="flex" mt="24px" minHeight={50}>
          <Box display="flex" width="100%" bgcolor="#F3EFF7" px="20px" borderRadius="12px">
            <Box width="20px" marginTop="12px" height="15px" component="img" src={QuoteLeft} />
            <Box px="20px" py="12px" width="calc(100% - 40px)" display="flex" justifyContent="center" alignItems="center">
              <Typography fontSize="14px" color={theme.palette.text.primary} fontWeight="450">
                {pdata.desc1}
              </Typography>
            </Box>
            <Box alignSelf="flex-end" marginBottom="12px" width="20px" height="15px" component="img" src={QuoteRight} />
          </Box>
        </Box>
        {/* 한줄평[end] */}
      </Container>

      <List
        sx={(theme) => ({
          py: '22px',
          px: '20px',
          borderTop: '1px solid #f3f2f5',
          bgcolor: totalCount > 0 ? '#f3f2f5' : 'transparent',
          transition: 'background-color 1s',
          width: '100%',
          maxWidth: '444px',
          position: 'relative',
          overflow: 'auto',
          mx: 'auto'
        })}
        subheader={<li />}
        aria-label="주문가능 샵목록"
      >
        <ListSubheader sx={{ px: 0, borderTop: 'none', bgcolor: 'transparent' }}>
          <SpaceBetweenBox height="18px" mb="12px">
            <Typography component="span" fontSize={16} fontWeight={800} lineHeight="normal" color="text.primary">
              주문 가능 와인샵
              {searchState.searched ? (
                <Typography component="span" fontSize={16} fontWeight={800} lineHeight="normal" color="#9357E5">
                  &nbsp;{(totalCount ?? 0).toLocaleString()}
                </Typography>
              ) : (
                '을 찾는 중 입니다.'
              )}
            </Typography>
          </SpaceBetweenBox>
          <Box>{userLocationLabel}</Box>
        </ListSubheader>

        {/* 주문가능 입점샵 목록 */}
        {vendorListComponent}

        {searchState.searching && (
          <Box textAlign="center">
            x
            <CircularProgress size="1rem" color="inherit" />
          </Box>
        )}
      </List>

      {/* pdata 상세정보 */}
      <PdataInfo show={showPdataInfo} pdataId={pdata?._id} moveToReview={pdataInfoReviewTab} />

      {/* 키워드알림 등록 토스트 */}
      {showKeywordAlert && <KeywordAlarmAlert text={pdata?.name?.ko} show={showKeywordAlert} onClose={handleShowKeywordAlert} />}
    </Box>
  );
}

export default WineDetail;

const FilledRatingIcon = React.memo(() => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, fill: 'none' }} viewBox="0 0 12 12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0023 8.74691L3.41914 10.6767C3.36211 10.7193 3.29726 10.7502 3.22829 10.7677C3.15933 10.7852 3.08759 10.7889 3.01717 10.7787C2.94676 10.7684 2.87905 10.7444 2.81791 10.708C2.75678 10.6716 2.70341 10.6236 2.66085 10.5665C2.60826 10.4961 2.57362 10.4138 2.5599 10.327C2.54619 10.2401 2.55382 10.1513 2.58214 10.068L3.61855 7.01387L0.980291 5.15357C0.922006 5.11261 0.87237 5.06056 0.834224 5.0004C0.796078 4.94023 0.770171 4.87314 0.757985 4.80295C0.7458 4.73276 0.747575 4.66086 0.76321 4.59136C0.778844 4.52186 0.808031 4.45612 0.8491 4.39791C0.899815 4.32597 0.967294 4.26747 1.0457 4.22747C1.1241 4.18746 1.21107 4.16717 1.29909 4.16833L4.52246 4.21162L5.48409 1.13124C5.50518 1.06327 5.53946 1.00012 5.58497 0.945396C5.63047 0.890673 5.68631 0.845451 5.7493 0.812313C5.81228 0.779176 5.88118 0.758772 5.95205 0.752268C6.02293 0.745763 6.09439 0.753286 6.16235 0.774405C6.24632 0.800469 6.32268 0.846575 6.38485 0.908744C6.44702 0.970913 6.49313 1.04728 6.51919 1.13124L7.47558 4.21162L10.7003 4.16964C10.7715 4.1686 10.8423 4.18163 10.9085 4.20798C10.9747 4.23433 11.0351 4.27349 11.0862 4.32321C11.1372 4.37294 11.178 4.43224 11.2061 4.49774C11.2342 4.56324 11.2491 4.63363 11.25 4.7049C11.2511 4.79291 11.2308 4.87988 11.1908 4.95829C11.1508 5.03669 11.0923 5.10417 11.0204 5.15489L8.38736 7.01387L9.42377 10.068C9.44668 10.1354 9.4561 10.2066 9.45149 10.2776C9.44689 10.3486 9.42834 10.418 9.39691 10.4818C9.36548 10.5457 9.32178 10.6027 9.26831 10.6496C9.21485 10.6966 9.15266 10.7325 9.0853 10.7554C9.00205 10.7838 8.91316 10.7914 8.82631 10.7777C8.73945 10.764 8.65724 10.7293 8.58677 10.6767L7.29453 9.71182L6.0023 8.74691Z"
        fill="#FEC466"
      />
    </SvgIcon>
  );
});

const PeriscopeFilledIcon = React.memo(() => {
  return (
    <SvgIcon height={18} width={19} sx={{ color: '#220348', opacity: 0.7 }} viewBox="0 0 18 19">
      <path style={{ opacity: '.5', fill: 'none' }} d="M0 0h34v34H0z" />
      <path style={{ fill: 'none', opacity: '.5' }} d="M0 0h18v19H0z" />
      <path
        d="M7.321 14.284a.415.415 0 0 1-.3-.122L3.77 10.906a5.022 5.022 0 1 1 7.1 0l-3.253 3.255a.415.415 0 0 1-.296.123zM7.25 5.047a2.262 2.262 0 0 0-2.26 2.26v.1a2.26 2.26 0 0 0 4.52 0v-.1a2.262 2.262 0 0 0-2.26-2.26z"
        transform="translate(1.709 1.687)"
      />
    </SvgIcon>
  );
});
