import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getAuth } from 'firebase/auth';
import validator from 'validator/es';

// project imports
import { useAmplitude, useLocalStorage } from '../hooks';

// slices
import wineOneReducer, {
  APP_TYPE,
  fireNativeBackPressed,
  onNativeBackPressed as _onNativeBackPressed,
  setApplicationType,
  setApplicationVersion,
  setBottomNavigationValue,
  setGeolocation,
  setHealth,
  toggleBottomNavigation,
  USER_AGENT,
  WINEONE_INITIAL_STATE
} from 'store/slices/wine-one';

import { amplitude } from 'index';

import { WoAlert } from '../utils/kmwine-alerts';
import { setFcmToken } from '../services/UserService';
import { useDispatch } from '../store';
import { IS_PRODUCTION } from '../config';
import { getDatabase as getRealtimeDatabase, onValue, ref } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { isNull } from '../utils/bourgogne-tools';
import { closeBackdrop, openBackdrop } from '../store/slices/backdrop';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestore } from '../utils/firebase-tools';

// ----------------------------------------------------------------------

const WineOneContext = createContext(WINEONE_INITIAL_STATE);

/**
 * 와인원(1KMWINE)서비스 관련 provider
 * @constructor
 */
function WineOneProvider({ location, children }) {
  const [state, dispatch] = useReducer(wineOneReducer, WINEONE_INITIAL_STATE);
  const [settings, setSettings] = useLocalStorage('wine-one-app', {
    appType: null, // 앱타입 (web / Android / iOS )
    geolocationAccess: false, // 위치정보 접근가능여부
    userLocation: null
  });

  const { logEvent } = useAmplitude();
  const navigate = useNavigate();
  const globalDispatch = useDispatch();

  const lastHealth = React.useRef(state.health ?? { state: 'ok' });

  React.useLayoutEffect(() => {
    // 최초 구동시 health.state = 'ok'로 설정
    globalDispatch(setHealth({ state: 'ok' }));
    lastHealth.current = { state: 'ok' };
  }, []);

  // 웹 앱 상태확인 - realtime
  React.useLayoutEffect(() => {
    const query = ref(getRealtimeDatabase(), 'user_web/health');

    const unsub = onValue(
      query,
      (snapshot) => {
        if (snapshot.exists()) {
          const health = snapshot.val();
          console.log('[WineOneContext] User web app health: ', health, ', lastHealth: ', lastHealth.current);
          globalDispatch(setHealth({ ...health }));

          if (health.state === 'ok' && lastHealth.current.state !== 'ok') {
            // 앱 상태가 ok로 변경되었을 경우
            console.log('[WineOneContext] App state changed to ok. Navigate to index page.');
            // 앱 상태가 ok로 변경되었을 경우 처리
            navigate('/', { replace: true });
          }

          lastHealth.current = health;
        } else {
          // realtime database에 health정보가 없을 경우
          console.warn('[WineOneContext] realtime database health not found');
          globalDispatch(setHealth({ state: 'ok' }));
          lastHealth.current = { state: 'ok' };
        }
      },
      (error) => {
        console.log('[1KMWINE][WineOneContext] realtime database error: ', error);
        // todo 코드 안정화 될 때 까지는 오류발생시 앱 상태 'ok'
        globalDispatch(setHealth({ state: 'ok' }));
        lastHealth.current = { state: 'ok' };
      }
    );

    return () => {
      try {
        unsub();
      } catch (e) {
        /* DO NOTHING */
      } finally {
        console.warn('[WineOneContext] Realtime database unsubscribed [/user_web/health]');
      }
    };
  }, []);

  // 화면 주소 변경
  React.useEffect(() => {
    // 하단 네비게이션 선택값
    if (location && location.pathname) {
      const [_, firstPath] = location.pathname.split('/');
      dispatch(setBottomNavigationValue(firstPath));
    }

    return () => {
      // 기기의 뒤로가기 버튼콜백 초기화
      dispatch(_onNativeBackPressed(null));
    };
  }, [location?.pathname]);

  // URL 경로가 변경되면 SweetAlert닫기
  React.useEffect(() => {
    // 열려있던 SweetAlert닫기
    if (WoAlert.isVisible()) WoAlert.close();
  }, [location.pathname, location.search, location.hash]);

  const receiveMessage = React.useCallback((event) => {
    console.debug('[development]---------------------------------------------- event from application [start]');

    // event 처리 시작
    const { detail = null } = event;

    if (!detail) {
      console.warn(`Payload 'detail'를 통해 수신된 정보가 없습니다. 송신 데이터를 확인해주세요.`);
      return false;
    }

    const { type } = detail;

    try {
      const eventObjKeys = Object.keys(detail);
      if (eventObjKeys.length > 0) {
        console.groupCollapsed('[1KMWINE] 디바이스에서 수신된 앱 이벤트 데이터');
        eventObjKeys.forEach((key) => {
          console.log(`${key.padEnd(12, ' ')}:`, detail[key]);
        });
        console.groupEnd();
      }
    } catch (e) {
      /* DO NOTHING */
    }

    // 네이티브의 푸시메시지 수신 이벤트
    if (type === 'receive-push-msg') {
      console.debug(`-- 푸시메시지 수신됨. detail=`, detail);
      if (!IS_PRODUCTION) {
        console.debug('[1KMWINE] 개발환경에서는 푸시메시지 수신시 알림을 띄우지 않습니다.');
        window.alert(`[${type}]\r\n${JSON.stringify(detail, null, 2)}`);
        return;
      }
    }

    // FCM token changed
    else if (type === 'fcm-token-changed') {
      console.debug(`변경된 FCM token 수신됨. detail.token=${detail.token}`);

      // 현재 로그인사용자 확인
      const { currentUser } = getAuth();
      if (currentUser) {
        console.info('로그인 사용자의 갱신된 fcm token정보를 업데이트합니다.');
        if (detail.token) {
          setFcmToken(detail.token);
        } else {
          console.warn('[1kmwine] 토큰이 존재하지 않아 fcm token을 업데이트하지 않습니다.');
        }
      } else {
        console.warn('로그인하지 않은 상태입니다.');
      }
      return;
    }

    // Location changed
    else if (type === 'location-changed') {
      console.debug(`[1KMWINE] 변경된 location 정보 수신됨. detail=`, detail);
      try {
        if (validator.isLatLong(detail.location)) {
          const [latitude, longitude] = detail.location.split(',');
          console.debug('[1KMWINE] 사용자 위치 파악됨: ', latitude, longitude);
          // todo 대한민국 내 좌표가 아닐 경우 처리가 필요합니다.
          setSettings({
            ...settings,
            geolocationAccess: true,
            geolocationCoords: `${latitude},${longitude}`
          });
          globalDispatch(setGeolocation({ initialized: true, access: true, latitude, longitude }));
        } else {
          globalDispatch(setGeolocation({ initialized: true, access: false }));
        }
      } catch (e) {
        console.warn(`[1KMWINE] '[native].getLocation' 호출실패`, e);
        setSettings({ ...settings, geolocationAccess: false });

        globalDispatch(setGeolocation({ initialized: true, access: false }));
      }
    }

    // App version received
    else if (type === 'app-version') {
      console.debug(`-- 앱 버전정보 수신됨. detail.code=`, detail.code, detail.name);
      if (detail.code) {
        const { code, name } = detail;
        globalDispatch(setApplicationVersion(code, name));
      }
    } else if (type === 'device-id-changed') {
      if (detail.deviceId) {
        console.debug(`-- 디바이스 ID 변경됨. detail.deviceId=`, detail.deviceId);
        amplitude.getInstance().setDeviceId(detail.deviceId);
      }
    }

    // Health check
    else if (type === 'health-check') {
      // console.info(`%cwineone`, 'color: purple; background:pink; padding: 0 4px;', '이벤트(wo.application) 리스너가 정상 동작 중 입니다.');
      console.info('이벤트(wo.application) 리스너가 정상 동작 중 입니다.');
    }

    // 지정되지 않은 메시지 타입
    else if (type === 'help') {
      const helpTxt = `[development] 현재 테스트 가능한 타입.
  - receive-push-msg: 네이티브의 푸시 수신 데이터 전달
  - fcm-token-changed: 네이티브의 fcm token 갱신될 때마다 전달
  - health-check: 웹뷰의 스크립트 정상여부 확인(테스트용)
      `;
      console.log(helpTxt);
    }

    // 카드 큐레이션 상세화면으로 바로가기
    else if (type === 'curation-card') {
      // 예제 - 카드 큐레이션 상세화면으로 이동하는 wo.application 이벤트 테스트
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'curation-card', data: 'cZz7Dq7wLO746NM5JkcQ' } }));
      const { data: curationCardId } = detail;
      // 잘못된 아이디를 넘겼을 경우
      if (isNull(curationCardId)) return;

      globalDispatch(openBackdrop({ open: true, text: '기획상품으로 이동합니다.' }));

      // 카드 큐레이션 상세화면으로 이동
      setTimeout(() => {
        navigate(`/home/curation-card/${curationCardId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }

    // 필터 큐레이션 상품목록 화면으로 바로가기
    else if (type === 'curation-filter') {
      // 예제 - 필터 큐레이션 상품목록 화면으로 이동하는 wo.application 이벤트 테스트
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'curation-filter', data: '8NwFJupzULLMjGX4w6wJ' } }));
      const { data: curationFilterId } = detail;
      // 잘못된 아이디를 넘겼을 경우
      if (isNull(curationFilterId)) return;

      globalDispatch(openBackdrop({ open: true, text: '기획상품으로 이동합니다.' }));

      // 필터 큐레이션 상품목록 화면으로 이동
      setTimeout(() => {
        navigate(`/home/curation/${curationFilterId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }

    // 메인컨텐츠(구 와인디스커버리) 상세화면으로 바로가기
    else if (type === 'wine-discovery' || type === 'main-content') {
      // 예제 - 메인컨텐츠(구 와인디스커버리) 상세화면으로 이동하는 wo.application 이벤트 테스트
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'wine-discovery', data: 'QtadCLYSuFtPgspxFVMg' } }));
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'main-content', data: 'QtadCLYSuFtPgspxFVMg' } }));
      const { data: contentId } = detail;
      // 잘못된 아이디를 넘겼을 경우
      if (isNull(contentId)) return;

      globalDispatch(openBackdrop({ open: true, text: '컨텐츠 상세화면으로 이동합니다.' }));

      // 메인컨텐츠 상세화면으로 이동
      setTimeout(() => {
        navigate(`/magazine/${contentId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }

    // 입점샵 상세 바로가기
    else if (type === 'vendor') {
      // 예제 - 입점샵으로 이동하는 wo.application 이벤트 테스트
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'vendor', data: 'O0ptYv5QUMTGjU5E4DFW' } }));
      const { data: vendorId } = detail;
      // 잘못된 vendorId를 넘겼을 경우
      if (isNull(vendorId)) return;

      globalDispatch(openBackdrop({ open: true, text: '입점샵으로 이동합니다.' }));

      try {
        getDoc(doc(getFirestore(), 'vendor', vendorId)).then((snapshot) => {
          if (snapshot.exists()) {
            const vendor = snapshot.data();
            // Amplitude Select Store
            logEvent.selectStore('wo.application.vendor', vendorId, vendor.biz?.name);
          }
        });
      } catch (e) {
        /* DO NOTHING */
      }

      // 입점샵으로 이동
      setTimeout(() => {
        navigate(`/vendor/d/${vendorId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }
    // 주문 상세 바로가기
    else if (type === 'order') {
      const { data: oid } = detail;
      // 잘못된 oid를 넘겼을 경우
      if (isNull(oid)) return;

      globalDispatch(openBackdrop({ open: true, text: '주문상세 화면으로 이동합니다.' }));

      // 주문 상세로 이동
      setTimeout(() => {
        navigate(`my/order/${oid}/detail`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }
    // 이벤트 상세 바로가기
    else if (type === 'event') {
      const { data: evtId } = detail;
      // 잘못된 evtId를 넘겼을 경우
      if (isNull(evtId)) return;

      globalDispatch(openBackdrop({ open: true, text: '이벤트 화면으로 이동합니다.' }));

      // 이벤트 페이지로 이동
      setTimeout(() => {
        navigate(`my/event/${evtId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }
    // 마이페이지 바로가기
    else if (type === 'mypage') {
      globalDispatch(openBackdrop({ open: true, text: '마이페이지로 이동합니다.' }));

      // 입점샵으로 이동
      setTimeout(() => {
        navigate(`/my`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }
    // 주문가능 매장 바로가기
    else if (type === 'pdata') {
      const { data: pdataId } = detail;
      // 잘못된 pdataId를 넘겼을 경우
      if (isNull(pdataId)) return;

      globalDispatch(openBackdrop({ open: true, text: '주문가능 매장으로 이동합니다.' }));

      // 주문가능 매장으로 이동
      setTimeout(() => {
        // 모든 지역의 입점샵(vendor) 목록이 뜨도록 (pin=3)
        navigate(`mall/wine/${pdataId}?pin=3`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }
    // [PROD-271] 오늘의 한병 주문 바로가기
    else if (type === 'today-bottle-order') {
      const { data: productId } = detail;
      // 잘못된 productId를 넘겼을 경우
      if (isNull(productId)) return;
      globalDispatch(openBackdrop({ open: true, text: '주문화면으로 이동합니다.' }));

      // 오늘의 한병 주문확인 화면으로 이동
      setTimeout(() => {
        navigate(`order/today-bottle/confirm?pid=${productId}`);
        globalDispatch(closeBackdrop());
      }, 800);
    }
    // [PROD-308] 공동구매 화면 바로가기
    else if (type === 'cobuy') {
      const { data: cobuyId } = detail;
      // 잘못된 productId를 넘겼을 경우
      if (isNull(cobuyId)) return;
      globalDispatch(openBackdrop({ open: true, text: '공동구매 화면으로 이동합니다.' }));

      // 공동구매 화면으로 이동
      setTimeout(() => {
        navigate(`co-buying/${cobuyId}`);
        window.location.reload();
        globalDispatch(closeBackdrop());
      }, 800);
    }
    // [임시] 티켓주문 바로가기 - PROD-223  (럭키박스 시즌2구매 페이지로 바꿈)
    else if (type === 'ticket-order') {
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'ticket-order', data: 'm4hRGc2XLULfD0gfL4vT' } }))
      const { data: productId } = detail;

      // 잘못된 productId를 넘겼을 경우
      if (isNull(productId)) return;

      globalDispatch(openBackdrop({ open: true, text: '럭키박스 주문화면으로 이동합니다.' }));

      // 티켓 주문확인 화면으로 이동
      setTimeout(() => {
        navigate(`order/ticket/confirm?pid=${productId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    } else if (type === 'promotion') {
      // window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'ticket-order', data: 'm4hRGc2XLULfD0gfL4vT' } }))
      const { data: promotionId } = detail;

      // 잘못된 productId를 넘겼을 경우
      if (isNull(promotionId)) return;

      globalDispatch(openBackdrop({ open: true, text: '프로모션 페이지로 이동합니다.' }));

      // 티켓 주문확인 화면으로 이동
      setTimeout(() => {
        navigate(`promotion/${promotionId}`);
        globalDispatch(closeBackdrop());
      }, 1200);
    }
    // URL만 이동 시 사용 (2023/11/01 작업)
    else if (type === 'url-go') {
      const { data: url } = detail;
      // 잘못된 url를 넘겼을 경우
      if (isNull(url)) return;

      globalDispatch(openBackdrop({ open: true, text: '화면으로 이동합니다.' }));

      // 페이지로 이동
      setTimeout(() => {
        navigate(url);
        globalDispatch(closeBackdrop());
      }, 1200);
    } else {
      console.warn(`지정되지 않은 메시지 타입입니다.[type=${type}, detail=${JSON.stringify(detail)}]`);
    }

    console.debug('[development]---------------------------------------------- event from application [end]');
    return event;
  }, []);

  React.useEffect(() => {
    window.addEventListener('wo.application', receiveMessage, false);
    console.log(`[WineOneContext] Event Listener added: 'wo.application'`);
    return () => {
      window.removeEventListener('wo.application', receiveMessage);
      console.log(`[WineOneContext] Event Listener removed: 'wo.application'`);
    };
  }, [receiveMessage]);

  // 어느 어플리케이션에서 호출했는지 확인
  React.useEffect(() => {
    const { navigator } = window;
    const { userAgent } = navigator;
    let appType = APP_TYPE.WEB;
    const isApplication = userAgent.includes(USER_AGENT.PREFIX);

    // 어플리케이션에서 접근
    if (isApplication) {
      // 안드로이드에서 접근
      if (userAgent.includes(USER_AGENT.AOS)) {
        appType = APP_TYPE.AOS;
      }
      // iOS에서 접근
      else if (userAgent.includes(USER_AGENT.IOS)) {
        appType = APP_TYPE.IOS;
      }
    }

    setSettings({
      ...settings,
      appType
    });
    dispatch(setApplicationType(appType));
    console.debug(`[1KMWINE] 감지된 어플리케이션 타입 = '${appType}'`);

    // 프로덕션에서 웹(URL)접근 했을 경우 앱 소개페이지로 이동
    if (IS_PRODUCTION && appType === APP_TYPE.WEB) {
      window.location.href = 'https://www.1kmwine.kr';
    }

    // 어플리케이션 하드웨어 이벤트 핸들러
    if (isApplication || process.env.NODE_ENV === 'development') {
      window.addEventListener('wo.hardware', hardwareEvent, false);
    }

    // 프로바이더 언마운트 됨
    return () => {
      console.debug(`[1KMWINE] WineOne context unmount.`);

      if (isApplication) {
        window.removeEventListener('wo.hardware', hardwareEvent);
      }
    };
  }, []);

  // 사용자 위치정보 확인
  React.useLayoutEffect(() => {
    console.log('[1KMWINE] 사용자 위치정보 확인시작');
    // console.log('############################################### refreshUserGeolocation: ', refreshUserGeolocation);
    refreshUserGeolocation();
  }, [state.appType]);

  const refreshUserGeolocation: Promise<void> = async () => {
    if (!state.appType) return;
    console.debug(`[1KMWINE] 사용자의 'geoLocation' 정보를 가져오기 시작합니다. [appType=${state.appType}]`);
    // web호출일 경우 browser의 geolocation을 통해 사용자 위치정보를 조회
    if (state.appType === APP_TYPE.WEB) {
      requestBrowserGeolocation(globalDispatch, state).then(() => {
        console.debug('[1KMWINE] 웹의 geolocation 정보를 활성화했습니다.');
      });
    }
    // 안드로이드의 경우 device의 위치정보 조회를 요청
    else if (state.appType === APP_TYPE.AOS) {
      // 안드로이드 위치정보
      try {
        const geolocation = window.kmwine.getLocation();

        // 2023-04: 위치정보를 허용안한 안드로이드에서 좌표를 0.0으로 반환하는 경우가 있음
        const [latitude, longitude] = geolocation.split(',');
        console.info('[1KMWINE:android] getLocation 호출결과: ', geolocation, typeof geolocation, latitude, longitude);

        // 위치접근 허용안했는데, 아래처럼 넘어오는 경우가 있음
        if (latitude === '0.0') {
          console.warn('[1KMWINE:android] 위치접근 허용안한 기기');
          setSettings({
            ...settings,
            geolocationAccess: false
          });
          globalDispatch(setGeolocation({ initialized: true, access: false }));
        }
        // 안드로이드의 위치정보 확인
        else {
          console.debug('[1KMWINE] 사용자 위치 파악됨: ', latitude, longitude);
          setSettings({
            ...settings,
            geolocationAccess: true,
            geolocationCoords: `${latitude},${longitude}`
          });

          globalDispatch(setGeolocation({ initialized: true, access: true, latitude, longitude }));
        }
      } catch (e) {
        console.warn(`[1kmwine:android] 'window.kmwine.getLocation' 호출실패`, e);
        // access = false;
        setSettings({
          ...settings,
          geolocationAccess: false
        });
        // dispatch(setUserGeolocation({ initialized: true, access: false }));
        globalDispatch(setGeolocation({ initialized: true, access: false }));
        // console.warn('[wineone:android] 위치정보 조회 실패.', e);
      }
    }
    // iOS의 경우 device의 위치정보 조회를 요청
    else if (state.appType === APP_TYPE.IOS) {
      console.log('iOS 위치정보 요청 - > wo.application[type=location-changed] 핸들러에서 처리예정');
      window.webkit.messageHandlers.kmwine.postMessage('getLocation');
    }
  };

  React.useEffect(() => {
    console.debug('[1KMWINE] 사용자 위치정보 접근가능 여부 = ', state.geolocation.access);

    setSettings({
      ...settings,
      geolocationAccess: state.geolocation.access
    });
  }, [state.geolocation.access]);

  // 하단 네비게이션 보이기(수동)
  const showBottomNav = () => {
    dispatch(toggleBottomNavigation(true));
  };
  // 하단 네비게이션 감추기(수동)
  const hideBottomNav = () => {
    dispatch(toggleBottomNavigation(false));
  };
  // 하단 네비게이션 노출여부 초기화(자동)
  const resetBottomNav = () => {
    dispatch(toggleBottomNavigation(undefined));
  };
  // 하단 네비게이션 선택값
  const setBottomNavVal = (value) => {
    dispatch(setBottomNavigationValue(value));
  };

  // 기기의 '뒤로가기'버튼 콜백 정의
  const onNativeBackPressed = (callback) => {
    // console.log('@WineOneContext.onNativeBackPressed: ', callback);
    if (typeof callback === 'function') {
      dispatch(_onNativeBackPressed(callback));
    } else {
      dispatch(_onNativeBackPressed(null));
      console.warn(`[wineone] 'onNativeBackPressed'의 'callback'이 'function'이 아닙니다. [typeof callback = ${typeof callback}]`);
    }
  };

  // const [appState, setAppState] = React.useState('ok');

  // render
  return (
    <WineOneContext.Provider
      value={{
        ...state,
        refreshUserGeolocation,
        showBottomNav,
        hideBottomNav,
        resetBottomNav,
        setBottomNavVal,
        onNativeBackPressed
      }}
    >
      {children}
    </WineOneContext.Provider>
  );
  // PRIVATE FUNCTIONS

  // 기기에서 하드웨어 이벤트 메시지 수신
  function hardwareEvent(event) {
    const { detail } = event;
    if (detail) {
      const { action } = detail;

      // 기기에서 뒤로가기 버튼 눌렸을 때
      if (action === 'backpressed') {
        console.log('[wineone] 모바일 기기의 뒤로가기 이벤트가 감지되었습니다.');
        dispatch(fireNativeBackPressed());
      }
      // 앱에 foreground로 진입
      // window.dispatchEvent(new CustomEvent('wo.hardware', {detail: {action: 'foreground'}}));
      else if (action === 'foreground') {
        const auth = getAuth();

        if (auth.currentUser) {
          console.log('old token: ', auth.currentUser.accessToken);
          try {
            auth.currentUser.getIdToken(true).then((token) => {
              console.log('refreshed token3: ', token);
            });
          } catch (e) {
            console.warn('[wineone] Failed to get authentication token.', e);
          }
        }
      } else {
        console.warn('[wineone] 정의되지 않은 이벤트가 수신되었습니다.', event);
      }
    } else {
      console.warn('[wineone] 정의되지 않은 이벤트가 수신되었습니다.', event);
    }
  }
}

WineOneProvider.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node
};

export { WineOneProvider, WineOneContext };

// ----------------------------------------------------------------------
/**
 * 브라우저의 위치정보 조회
 */
async function requestBrowserGeolocation(globalDispatch, state) {
  console.debug('[1KMWINE] 브라우저에서 geolocation 정보 가져오기');
  const { navigator } = window;
  if (!navigator.geolocation) {
    console.warn('[wineone] 위치정보를 확인할 수 없는 브라우저입니다.');

    globalDispatch(setGeolocation({ initialized: true, access: false }));
    window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'location-changed', location: '' } }));
    return;
  }

  // 사용자 위치관련
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  function success(position) {
    const { coords } = position;
    const { latitude, longitude } = coords;
    console.debug('[1KMWINE] 사용자 위치 파악됨: ', latitude, longitude);
    // console.log('-----------------> latitude: ', latitude);
    window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'location-changed', location: `${latitude},${longitude}` } }));
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  async function handlePermission() {
    const { browser } = state.currentUserAgent;

    // 모바일 사파리 브라우저
    if (browser.name === 'Mobile Safari') {
      navigator.geolocation.getCurrentPosition(
        success,
        (e) => {
          const { code, PERMISSION_DENIED, POSITION_UNAVAILABLE, TIMEOUT } = e;
          let msg;
          switch (code) {
            case PERMISSION_DENIED:
              msg = 'User did not share geolocation data';
              break;
            case POSITION_UNAVAILABLE:
              msg = 'Could not detect current position';
              console.warn(`[wineone] '현재위치 사용'기능을 사용할 수 없는 브라우저입니다.`);
              break;
            case TIMEOUT:
              msg = 'Retrieving position timed out';
              break;
            default:
              msg = 'Unknown error';
              break;
          }
          console.warn(`[1KMWINE] 모바일 사파리 위치정보 조회 실패 : `, msg);
          globalDispatch(setGeolocation({ initialized: true, access: false }));
          window.dispatchEvent(new CustomEvent('wo.application', { detail: { type: 'location-changed', location: '' } }));
        },
        options
      );
    }

    // 다른 모든 브라우저
    else {
      const result = await navigator.permissions.query({ name: 'geolocation' });
      if (result.state === 'granted') {
        report(result.state);
      } else if (result.state === 'prompt') {
        report(result.state);
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else if (result.state === 'denied') {
        report(result.state);
      }

      result.addEventListener('change', function () {
        console.log('[wineone] geolocation 접근권한이 변경되었습니다.', result.state);
        report(result.state);
      });
    }
  }

  function report(state) {
    // console.log('Permission ' + state);
    if (state === 'granted') {
      getCurrentGeolocation();
    } else if (state === 'denied') {
      // globalDispatch(setUserGeolocation({ initialized: true, access: false }));
      globalDispatch(setGeolocation({ initialized: true, access: false }));
    } else {
      // globalDispatch(setUserGeolocation({ initialized: true, access: undefined }));
      globalDispatch(setGeolocation({ initialized: true, access: undefined }));
    }
  }

  function getCurrentGeolocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log('Geolocation position : ', position);
      const { coords } = position;
      const { latitude, longitude } = coords;

      // globalDispatch(setUserGeolocation({ initialized: true, access: true, latitude, longitude }));
      console.debug('[1KMWINE:WEB] 사용자의 위도경도 정보 확인됨.', latitude, longitude);
      globalDispatch(setGeolocation({ initialized: true, access: true, latitude, longitude }));
    });
  }

  await handlePermission();
}
