import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocationState } from 'react-router-use-location-state';
import { collection, doc, documentId, getDoc, getDocs, limit, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import moment from 'moment';

// project imports
import { useAuth, useScriptRef, useWineOne } from 'hooks';
import { getFirebaseFunctions, getFirestore } from 'utils/firebase-tools';
import { WoAlert } from 'utils/kmwine-alerts';
import BackHeader from 'layouts/Main/BackHeader';
import { CLO_CODE } from 'config';
import { Payment as WoPayment, Refund as WoRefund, VendorInfo, VendorLocationMap } from './components';
import { VendorBizTimeDialog } from '../../../pages/order/OrderPreview/components';
import { WoDivider } from '../../../../components';
import OrderProductList from './components/OrderProductList';
import { APP_TYPE } from 'store/slices/wine-one';
import { PdataInfo } from 'components/pdata';

// material-ui
import { Alert, Box, Button, CircularProgress, Collapse, Grow, Skeleton, Slide, Snackbar, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// 220921: 지도 높이 145에서 215로 변경
const VENDOR_LOCATION_MAP_HEIGHT = 145 + 70;

/**
 * MY > 주문상세 화면
 *
 * @authors 조현권<hkcho@wineone.io>, 김지현<jhkim@wineone.io>, 이재일<leeji@wineone.io>
 */
function OrderDetail() {
  const scriptedRef = useScriptRef();
  const location = useLocation();
  const navigate = useNavigate();

  const { appType } = useWineOne();
  const { user } = useAuth(); // 사용자 정보

  const { orderNo: oid } = useParams(); // 주문번호(oid)

  // 주문정보
  const [order, setOrder] = React.useState({ loaded: false });

  /**
   * 주문 상품 배열
   * 1. REQ -> 주문 승인 대기
   * 2. CANCEL -> 전체취소, 부분취소
   * 3. REQ_OK -> 전체승인, 부분승인
   * 4. PICK_OK -> 픽업완료
   * 5. CONFIRM -> 구매확정
   * 6. EXCRTN -> 모든 교환상태, 모든 환불상태 (상세페이지 에서는 교환/환불의 모든 상태를 그룹화 하여 표현)
   */
  const [orderProducts, setOrderProducts] = React.useState({
    CO_BUY: [], // [PROD-308] 공동구매
    PROMOTION: [],
    REQ: [],
    CANCEL: [],
    REQ_OK: [],
    PICK_OK: [],
    CONFIRM: [],
    EXCRTN: {
      show: false
    }
  });

  // 입점샵 정보
  const [vendor, setVendor] = React.useState({ loaded: false });
  // 입점사 매장 영업시간 다이어로그 열림여부
  const [vendorBizTimeOpen, setVendorBizTimeOpen] = useLocationState('vbtOpen', false);

  // 주문정보 조회
  React.useLayoutEffect(() => {
    fetchOrder();
  }, [oid, order.loaded]);

  // 다이나믹링크로 들어왔을시 해당 사용자의 주문이 아닐떄
  React.useLayoutEffect(() => {
    // todo dynamic link 대응, 2025-08
    // 자신의 주문이 아닐 경우, 뒤에 주문정보 자체가 나타나지 않도록 수정
    if (order.loaded && order.user.uid !== user._id) {
      WoAlert.fire('', `해당 사용자의 주문이 아닙니다.<br />[주문번호=${oid}]`, 'error').then(() => {
        navigate(-1);
      });
    }
  }, [order]);

  const now = moment(); // 현재시간 - 의도적으로 memoize 안함

  // 구매시 사용된 쿠폰 정보
  const [usedCoupon, setUsedCoupon] = React.useState({
    loaded: false,
    error: false,
    datas: []
  });

  // 주문시 사용한 쿠폰 조회
  React.useEffect(() => {
    if (!order.loaded) {
      console.debug('[주문상세] 아직 주문정보가 로드되지 않았습니다.');
      return false;
    }

    // 결제시 사용한 쿠폰(coupon)정보 조회
    if (!usedCoupon.loaded) {
      // 결제시 사용한 쿠폰이 있음
      if (order.coupon.length > 0) {
        console.debug('[주문상세] 결제시 사용된 쿠폰을 조회합니다. ', user._id, order.coupon);
        setUsedCoupon({ loaded: false, error: false, datas: [] });

        // 결제시 사용한 쿠폰 상세정보 조회
        getDocs(query(collection(getFirestore(), `member/${user._id}/coupon_download`), where(documentId(), 'in', order.coupon)))
          .then((querySnapshot) => {
            if (scriptedRef.current) {
              const datas = [];
              querySnapshot.docs.forEach((couponDownloadDoc) => {
                datas.push(couponDownloadDoc.data());
              });
              console.debug('결제시 사용한 쿠폰정보 조회 완료. ', datas);
              setUsedCoupon({ loaded: true, error: false, datas });
            }
          })
          .catch((error) => {
            console.error('[1kmwine] 사용쿠폰정보 조회 실패 ', error);
            if (scriptedRef.current) {
              setUsedCoupon({ loaded: true, error: true, datas: [] });
            }
          });
      } else {
        console.info('[OrderDetail] 결제시 사용된 쿠폰이 없습니다.');
        if (scriptedRef.current) {
          setUsedCoupon({ loaded: true, error: false, datas: [] });
        }
      }
    }
  }, [order?.loaded]);

  // 입점샵 영업시간 data
  const bizHour = React.useMemo(() => {
    if (!vendor.loaded) return undefined;
    const {
      shop: { biz_hours }
    } = vendor;

    //  Business hour of today.
    const day = moment().locale('en').format('ddd').toUpperCase();
    return biz_hours ? biz_hours[day] : null;
  }, [vendor.loaded]);

  // 입점샵 영업 시간 표현
  const bizHourStr = React.useMemo(() => {
    if (!vendor.loaded) return undefined;

    // 영업시간 정보가 없을 경우
    if (!vendor?.shop?.biz_hours || bizHour === null) {
      return (
        <Typography variant="caption" color={(theme) => theme.palette.text.disabled}>
          [ 영업시간 정보가 없습니다 ]
        </Typography>
      );
    }

    let str;

    // 입점샵이 영업하는 날
    if (bizHour.available) {
      const startTime = moment(`${bizHour.open_hour}`.padStart(2, '0') + `${bizHour.open_min}`.padStart(2, '0'), 'HHmm').format('A hh:mm');
      const closeTime = moment(`${bizHour.close_hour}`.padStart(2, '0') + `${bizHour.close_min}`.padStart(2, '0'), 'HHmm').format(
        'A hh:mm'
      );
      str = (
        <Typography>
          {startTime} ~ {closeTime}
        </Typography>
      );
    }
    // 입점샵 휴일
    else {
      str = <Typography>휴일</Typography>;
    }

    return str;
  }, [bizHour]);

  // 픽업일시 컨텐츠
  const pickupDateContent = React.useMemo(() => {
    if (!order.loaded) return <Skeleton width={140} />;

    const { cobuy_state = 'NONE' } = order;

    const isCoBuyOrder = cobuy_state !== 'NONE';

    if (order.promotion?.pickup?.state === 'none' && (order.state === 'PICK_OK' || order.state === 'CONFIRM')) {
      return <Typography>{moment(order.pick_ok_at.toDate()).format('YYYY년 MM월 DD일 (dd) a hh:mm')}</Typography>;
    }

    if (order.promotion?.pickup?.state === 'none') {
      return <Typography color="rgba(60, 174, 71, 0.70)">예약 마감 후 별도 안내</Typography>;
    }

    // 공동구매 주문일 경우
    if (isCoBuyOrder) {
      // 실패한 공동구매
      if (order.state === 'CANCEL' || order.cobuy_state === 'FAIL') {
        return '공동구매 취소';
      }

      if (order.coBuy.vendor.state === 'OK' && (order.state === 'REQ_OK' || order.state === 'PICK_OK')) {
        const pickEndMmt = moment(order.coBuy.pick_end_dt.toDate());
        const format = now.year() === pickEndMmt.year() ? 'M.D(ddd)' : 'YY.M.D(ddd)';
        return `${pickEndMmt.format(format)}까지`;
      }
      return '주문 마감 후 별도 안내';
    }

    const pickupMmt = moment(`${order.pickup_date} ${order.pickup_time}`, 'YYYY-MM-DD HH:mm');
    const format = now.year() === pickupMmt.year() ? 'MM월 DD일 (ddd) A hh:mm' : 'YYYY년 MM월 DD일 (ddd) A hh:mm';
    return pickupMmt.format(format);
  }, [order.loaded]);

  // 와인샵 주소 클립보드 복사안내 스낵바(toast)
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: ''
  });

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      message: '',
      open: false
    });
  };

  const clipOrderNo = React.useCallback(() => {
    if (!vendor.loaded) return;
    const clipText = `${vendor.biz.address1} ${vendor.biz.address2}`;
    try {
      // 안드로이드의 경우
      if (appType === APP_TYPE.AOS) {
        window.kmwine.copyToClipboard('kmwine_shop_addr', clipText);
        setSnackbar({
          open: true,
          message: `주소가 클립보드에 저장되었습니다.\r\n[${clipText}]`
        });
      } else if (typeof window.navigator.share !== 'undefined') {
        console.log('Use navigator.share');
        window.navigator
          .share({
            title: `${vendor.biz.name} | 1KMWINE`,
            text: clipText
          })
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Use navigator.clipboard');
        window.navigator.clipboard.writeText(clipText);
        setSnackbar({
          open: true,
          message: `주소가 클립보드에 저장되었습니다.\r\n[${clipText}]`
        });
      }
    } catch (e) {
      console.warn('[1KMWINE] 와인샵 주소 클립보드 저장 실패.', e);
    }
  }, [vendor.loaded]);

  // 상세보기할 pdata Id
  const [detailPdataId, setDetailPdataId] = useLocationState('my_order_detail_pdataId', null);
  // pdata 상세보기
  const [showPdataInfo, setShowPdataInfo] = useLocationState('my_order_detail_showPdataInfo', false);

  const moveToPdataInfo = async (pdataId) => {
    if (showPdataInfo) return false;

    if (!pdataId) {
      console.error('[OrderList][moveToPdataInfo] pdataId is undefined');
      return undefined;
    }

    setDetailPdataId(pdataId);
    setShowPdataInfo(true, { method: 'push' });
  };

  // render
  return (
    <BackHeader title="주문상세" showFooter={false}>
      <Helmet title={`${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE} | 주문상세`} />

      {/* 입점샵 정보/주문번호/주문일시 */}
      <VendorInfo vendor={vendor} order={order} />

      <WoDivider />

      {/* 주문 상품 목록 */}
      {order.loaded ? (
        <OrderProductList order={order} orderProducts={orderProducts} moveToPdataInfo={moveToPdataInfo} />
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" bgcolor="#F3F2F5" height="226px">
          <CircularProgress size={24} />
        </Box>
      )}

      {/* 픽업정보[start] */}
      <Box px="20px" pt="24px" pb="32px">
        <Typography variant="subtitle1" fontSize={14} fontWeight={900} lineHeight="normal">
          {order.is_ticket ? '주문정보' : '픽업정보'}
        </Typography>

        {/* 입점샵 위치 지도 */}
        <Box height={VENDOR_LOCATION_MAP_HEIGHT} my="20px" borderRadius="5px" overflow="hidden" position="relative">
          {!vendor.loaded && <MapLoadingProgress />}
          <VendorLocationMap height={VENDOR_LOCATION_MAP_HEIGHT} vendor={vendor} />
        </Box>

        <Stack direction="column" spacing="16px">
          <Collapse in={!vendor.deleted}>
            {/* 매장주소 */}
            <PickupStack title="주소">
              <PickupContent onContextMenu={clipOrderNo}>
                {vendor.loaded ? `${vendor.biz.address1} ${vendor.biz.address2}` : <Skeleton width={140} />}
              </PickupContent>
            </PickupStack>
          </Collapse>
          {/* 영업 시간 */}
          <PickupStack title="영업시간">
            <PickupContent component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              {vendor.loaded ? bizHourStr : <Skeleton width={140} />}
              <Grow in={vendor.loaded && !!vendor?.shop?.biz_hours}>
                <Box>
                  <BizTimeButton
                    onClick={() => {
                      // setVendorBizTimeOpen(true);
                      setVendorBizTimeOpen(true, { method: 'push' });
                    }}
                  >
                    <Typography variant="caption" lineHeight="22px">
                      시간 전체보기
                    </Typography>
                  </BizTimeButton>
                </Box>
              </Grow>
            </PickupContent>
          </PickupStack>

          {/* 픽업 일시 */}
          <PickupStack title={order.is_ticket ? '주문일시' : '픽업일시'}>
            {/* 공동구매상품 픽업일시 표현 */}
            <PickupContent>{pickupDateContent}</PickupContent>
          </PickupStack>

          {/* 구매자 */}
          <PickupStack title="구매자">
            <PickupContent>
              {user.uname ?? <Skeleton width={40} />}&nbsp;{user.email ? `(${user.email})` : <Skeleton width={80} />}
            </PickupContent>
          </PickupStack>
        </Stack>
      </Box>
      {/* 픽업정보[end] */}

      {order.from === 'keymedi2408' && (
        <Box mb={2.5} px={2}>
          <Alert severity="info">
            <Typography fontSize={14} lineHeight="21px" letterSpacing="-0.3px">
              키메디 공동구매 주문입니다
            </Typography>
          </Alert>
        </Box>
      )}

      {/* 결제 정보 [start] */}
      <Payment order={order} usedCoupon={usedCoupon} />
      {/* 결제 정보 [end] */}

      {/* 환불 정보 [start] */}
      <Refund order={order} usedCoupon={usedCoupon} />
      {/* 환불 정보 [end] */}

      {/* 매장 영업시간 다이어로그 */}
      <VendorBizTimeDialog
        open={vendorBizTimeOpen}
        vendor={vendor}
        onClose={() => {
          // setVendorBizTimeOpen(false);
          navigate(-1);
        }}
      />

      {/* 와인샵 주소 복사안내 스낵바 */}
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2500}
        onClose={handleSnackbarClose}
        TransitionComponent={SnackBarTransition}
        key="oid-clipboard-snackbar"
      >
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%', whiteSpace: 'pre-line' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* pdata 상세정보 */}
      <PdataInfo show={showPdataInfo} pdataId={detailPdataId} />
    </BackHeader>
  );

  /** Firestore에서 주문정보 조회 */
  async function fetchOrder(): Promise<any> {
    let _order = null;

    // path param 변경 대응
    // window.dispatchEvent(new CustomEvent('wo.application', {detail: {type: 'order', data: '230427143329WHVFA'}}));
    if (!order.loaded) {
      const orderSnap = await getDoc(doc(getFirestore(), 'order', oid)).catch((error) => {
        console.error('[1KMWINE] 주문정보 조회 실패', error);
        // 주문상세 화면에서 주문정보 조회 실패
        try {
          const auth = getAuth();
          const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
          sendCloError({
            code: CLO_CODE.ORDER_LOAD_ERROR,
            title: `주문상세 화면에서 주문조회 실패 [uid=${auth.currentUser.uid}, oid=${oid}]`,
            msg: `${JSON.stringify(error)}`,
            which: `${location.pathname}${location.search}`
          })
            .then(console.log)
            .catch(console.error);
        } catch (e) {
          /* DO NOTHING */
        }

        WoAlert.fire(`주문정보 조회 중 오류가 발생했습니다.\n[주문번호=${oid}]`, '', 'warning').then(() => {
          navigate(-1);
        });
      });

      // When order exists.
      if (orderSnap.exists()) {
        // Get order items from subcollection 'order_products'.
        const orderProductsSnapshot = await getDocs(collection(orderSnap.ref, 'order_products'));

        if (!scriptedRef.current) {
          console.warn('[OrderDetail][fetchOrder] Unmounted component.');
          return;
        }

        // [PROD-308] 공동구매정보
        const { cobuy_id, cobuy_state = 'NONE' } = orderSnap.data();

        let isCoBuyOrder = false;
        let coBuy = {};
        if (cobuy_state !== 'NONE') {
          const coBuySnapshot = await getDoc(doc(getFirestore(), `co_buying`, cobuy_id));
          if (!scriptedRef.current) {
            console.warn('[OrderDetail][fetchOrder] Unmounted component.#2');
            return;
          }

          isCoBuyOrder = true;
          coBuy = coBuySnapshot.data();

          // 입점샵의 공동구매 정보 조회
          const { _id: co_buying_id } = coBuy;
          const {
            vendor: { id: co_buy_vendor_id }
          } = orderSnap.data();

          const coBuyVendorMapSnapshot = await getDocs(
            query(
              collection(getFirestore(), `co_buying_vendor`),
              where('co_buying_id', '==', co_buying_id),
              where('vendor_id', '==', co_buy_vendor_id),
              limit(1)
            )
          );

          if (!scriptedRef.current) {
            console.warn('[OrderDetail][fetchOrder] Unmounted component.#3');
            return;
          }
          const coBuyingVendor = coBuyVendorMapSnapshot.docs[0].data();
          coBuy.vendor = { state: coBuyingVendor.state };
        }

        let isPromotionOrder = false;
        let promotion = {};

        if (orderSnap.data().promotion_id) {
          const promotionRef = doc(getFirestore(), `promotion`, orderSnap.data().promotion_id);
          const promotionSnapShot = await getDoc(promotionRef);
          if (!scriptedRef.current) {
            console.warn('[OrderDetail][fetchOrder] Unmounted component.#2');
            return;
          }

          isPromotionOrder = true;
          promotion = promotionSnapShot.data();

          const vendorId = orderSnap.data().vendor.id;

          // 예약판매일 경우
          if (promotion.type === 'reserve') {
            // 입점샵의 입고상태 조회하기

            const promotionVendorRef = collection(getFirestore(), `promotion/${promotion.id}/promotion_vendor`);

            const q = query(promotionVendorRef, where('vendor_id', '==', vendorId));

            const { docs } = await getDocs(q);

            promotion.promotion_vendor = docs[0].data();

            if (!scriptedRef.current) return console.warn('[OrderDetail][fetchOrder] Unmounted component.#3');

            console.debug('예약판매 프로모션 입고상태 추가됨', promotion);
          } else {
            console.warn('프로모션 주문정보 처리하기');
          }
        }

        const orderItems = []; // order items array.

        const reqArr = []; // 승인 요청 배열(주문 확인 중)
        const confirmArr = []; // 구매 확정 배열
        const reqOkArr = []; // 승인 배열(전체 승인, 부분 승인)
        const pickOkArr = []; // 픽업 완료 배열
        const cancelArr = []; // 취소 배열(취소 ,거절)

        let excRtnShow = false; // 교환/반품 영역 표시 여부
        const reqRtnArr = []; // 반품 요청 배열
        const rtnWaitArr = []; // 반품 대기 배열
        const rtnOkArr = []; // 반품 완료 배열
        const reqExcArr = []; // 교환 요청 배열
        const excWaitArr = []; // 교환 대기 배열

        const coBuyArr = []; // [PROD-308] 공동구매 배열
        const promotionArr = [];

        // 취소 배열에 상품 추가
        const addToCancelArr = (orderState, orderProduct, originQuantity, quantity) => {
          const cancelQuantity = orderState === 'REQ_NO' || orderState === 'CANCEL' ? originQuantity : originQuantity - quantity;
          cancelArr.push({ ...orderProduct, cancel_quantity: cancelQuantity });
        };

        orderProductsSnapshot.forEach((orderItemDoc) => {
          // console.log('#isCoBuyOrder: ', isCoBuyOrder);

          // 주문 상태
          const orderState = orderSnap.data().state;

          // 주문 상품 데이터
          const orderProduct = orderItemDoc.data();
          // 주문 상품의 최종 주문 병 수, 주문 상품 상태
          const { quantity, state } = orderProduct;
          // 주문 상품의 최초 병 수 (부분 승인 개발 이전 주문 상품은 origin_quantity 대신 quantity 사용)
          const originQuantity = orderProduct.origin_quantity || quantity;
          // [PROD-308] 공동구매 주문일 경우
          if (isCoBuyOrder) {
            coBuyArr.push(orderProduct);
            // todo 주문취소 = 사용자 취소, 공동구매 실패
          } else if (isPromotionOrder && orderState !== 'PICK_OK') {
            promotionArr.push(orderProduct);
          } else {
            // 1. 주문의 상태로 분류
            switch (orderState) {
              // 1-1. 주문의 상태가 승인대기(REQ)일 경우
              case 'REQ':
                reqArr.push(orderProduct);
                break;
              // 1-2. 주문의 상태가 전체거절(REQ_NO) 또는 전체취소(CANCEL)일 경우
              case 'REQ_NO':
              case 'CANCEL':
                addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                break;
              // 1-3. 주문의 상태가 승인(REQ_OK) 또는 픽업완료(PICK_OK)일 경우
              case 'REQ_OK':
              case 'PICK_OK':
                // 2. 주문 상품의 상태로 분류
                switch (state) {
                  // 2-1. 상품의 상태가 구매확정(CONFIRM)인 경우
                  case 'CONFIRM':
                    confirmArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  // 2-2. 상품의 상태가 거절(REQ_NO)인 경우
                  case 'REQ_NO':
                    addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    break;
                  // 2-3. 상품의 상태가 교환(EXC_*)/반품(RTN_*) 인 경우
                  case 'REQ_RTN':
                    excRtnShow = true;
                    reqRtnArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  case 'RTN_WAIT':
                    excRtnShow = true;
                    rtnWaitArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  case 'RTN_OK':
                    excRtnShow = true;
                    rtnOkArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  case 'REQ_EXC':
                    excRtnShow = true;
                    reqExcArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  case 'EXC_WAIT':
                    excRtnShow = true;
                    excWaitArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  // 2-4. 상품의 상태가 픽업완료(PICK_OK)와 같은 기능을 가진 경우 -> 교환완료(EXC_OK), 반품거절(RTN_NO), 교환거절(EXC_NO)
                  case 'EXC_OK':
                  case 'RTN_NO':
                  case 'EXC_NO':
                    pickOkArr.push(orderProduct);
                    if (originQuantity !== quantity) {
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    }
                    break;
                  // 2-5. 상품의 상태가 승인(REQ_OK) 또는 부분승인(REQ_PART_OK)인 경우
                  case 'REQ_OK':
                  case 'REQ_PART_OK':
                    if (originQuantity !== quantity) {
                      reqOkArr.push(orderProduct);
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    } else {
                      reqOkArr.push(orderProduct);
                    }
                    break;
                  // 2-6. 상품의 상태가 픽업완료(PICK_OK)인 경우
                  case 'PICK_OK':
                    if (originQuantity !== quantity) {
                      pickOkArr.push(orderProduct);
                      addToCancelArr(orderState, orderProduct, originQuantity, quantity);
                    } else {
                      pickOkArr.push(orderProduct);
                    }
                    break;
                  default:
                    break;
                }
                break;
              default:
                break;
            }
          }

          orderItems.push(orderItemDoc.data());
        });

        _order = { ...orderSnap.data(), orderItems };

        // [PROD-308] 공동구매 정보
        if (isCoBuyOrder) _order.coBuy = coBuy;

        if (isPromotionOrder) _order.promotion = promotion;

        // 주문에 적용된 나라포스 캠페인 할인 정보
        const orderCampaignDocsSnap = await getDocs(collection(orderSnap.ref, 'order_campaigns'));
        if (!scriptedRef.current) return;

        const orderCampaigns = []; // 주문에 적용된 캠페인 배열

        if (orderCampaignDocsSnap.size > 0) {
          orderCampaignDocsSnap.forEach((orderCampaignDoc) => {
            orderCampaigns.push(orderCampaignDoc.data());
          });
          console.log('[주문상세] 주문에 적용된 캠페인이 있습니다.', orderCampaigns); // todo 확인 후 삭제
        } else {
          console.log('[주문상세] 주문에 적용된 캠페인이 없습니다.'); // todo 확인 후 삭제
        }

        // 주문정보
        setOrder({ ..._order, orderCampaigns, loaded: true });

        // 주문당시 입점샵 정보1
        console.log('[주문상세] 주문당시 입점샵 정보: ', _order.vendor);

        const vendorSnapshot = await getDoc(doc(getFirestore(), 'vendor', _order.vendor.id)).catch((error) => ({ error }));

        if (!scriptedRef.current) {
          console.warn('[OrderDetail][fetchOrder] Unmounted component.#3');
          return;
        }

        setVendor({
          ...vendorSnapshot.data(),
          loaded: true
        });

        const EXCRTN = {
          show: excRtnShow
        };

        if (reqRtnArr.length > 0) EXCRTN.REQ_RTN = reqRtnArr.sort((a, b) => a.req_rtn_at - b.req_rtn_at); // 반품요청 건이 있을 시
        if (rtnWaitArr.length > 0) EXCRTN.RTN_WAIT = rtnWaitArr.sort((a, b) => a.req_rtn_at - b.req_rtn_at); // 반품대기 건이 있을 시
        if (rtnOkArr.length > 0) EXCRTN.RTN_OK = rtnOkArr.sort((a, b) => a.rtn_ok_at - b.rtn_ok_at); // 반품완료 건이 있을 시
        if (reqExcArr.length > 0) EXCRTN.REQ_EXC = reqExcArr.sort((a, b) => a.req_exc_at - b.req_exc_at); // 교환요청 건이 있을 시
        if (excWaitArr.length > 0) EXCRTN.EXC_WAIT = excWaitArr.sort((a, b) => a.req_exc_at - b.req_exc_at); // 교환대기 건이 있을 시

        // 주문 상품들 상태별로 그룹화
        setOrderProducts({
          PROMOTION: promotionArr,
          CO_BUY: coBuyArr,
          REQ: reqArr,
          CANCEL: cancelArr,
          REQ_OK: reqOkArr,
          PICK_OK: pickOkArr,
          CONFIRM: confirmArr,
          EXCRTN
        });
      }
      // Order not exists.
      else {
        WoAlert.fire('', `주문정보가 존재하지 않습니다.<br />[주문번호=${oid}]`, 'error').then(() => {
          navigate(-1);
        });
      }
    } else {
      console.log('[주문상세] 이미 조회된 주문정보가 있습니다.');
      if (oid !== order.oid) {
        // path param이 변경됨
        console.log('주문번호 변경이 감지되었습니다.', `${order.oid} -> ${oid}`);

        // 입점샵 정보 초기화
        setVendor({ loaded: false });
        // 사용쿠폰 정보 초기화
        setUsedCoupon({ loaded: false, error: false, datas: [] });
        // 주문정보 초기화
        setOrder({ loaded: false });
      }
    }

    return _order;
  }
}

export default OrderDetail;

/* eslint-disable react/prop-types */
const PickupStack = ({ children, title }) => {
  const { pickupTitle } = useStyles();

  const _title = React.useMemo(() => {
    if (typeof title !== 'string') return title;

    const titleArr = title.split('').map((char, i) => {
      return <span key={`ttc-${char}-${i}`}>{char}</span>;
    });

    return <Box className="wrap">{titleArr}</Box>;
  }, [title]);

  // render
  return (
    <Stack
      direction="row"
      spacing="8px"
      divider={
        <Box display="flex" alignItems="center" py="3px">
          <Box height={1} width="1px" bgcolor={(theme) => alpha(theme.palette.text.primary, 0.1)} />
        </Box>
      }
      sx={{ minHeight: '16px' }}
    >
      <Box className={pickupTitle}>{_title}</Box>
      {children}
    </Stack>
  );
};

const PickupContent = styled(Typography)({
  fontSize: 14
});

const MapLoadingProgress = React.memo(() => (
  <Box
    position="absolute"
    top={0}
    right={0}
    bottom={0}
    left={0}
    zIndex={2}
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgcolor={(theme) => alpha(theme.palette.background.paper, 0.5)}
  >
    <Box textAlign="center">
      <CircularProgress size={24} />
      <br />
      <Typography variant="caption">지도를 불러오고 있습니다.</Typography>
    </Box>
  </Box>
));

// 영업시간 - 시간전체보기
const BizTimeButton = styled((props) => <Button variant="outlined" {...props} />)`
  height: 22px;
  border-color: #d9d3df;
  border-radius: 14px;
  padding: 0 11px;
  font-size: 11px;
  margin-left: 10px;
`;

/** 결제정보 */
// eslint-disable-next-line react/prop-types
const Payment = React.memo(({ order, usedCoupon }) => <WoPayment order={order} usedCoupon={usedCoupon} />);

/** 환불정보 */
// eslint-disable-next-line react/prop-types
const Refund = React.memo(({ order, usedCoupon }) => <WoRefund order={order} usedCoupon={usedCoupon} />);

const useStyles = makeStyles({
  pickupTitle: {
    width: 51,
    fontSize: 14,
    fontWeight: 300,
    '& .wrap': {
      width: '51px',
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& span': {
      opacity: 0.7
    }
  }
});

const SnackBarTransition = (props) => {
  return <Slide {...props} direction="down" />;
};
